import './logging/error.logger';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import '@fontsource/inter/300.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/700.css';
import { Provider } from 'react-redux';
import LazyApp from './LazyApp';
import { BrowserRouter as Router } from 'react-router-dom';
import { store } from './app-redux/store';
import CustomMuiTheme from './theme/CustomMuiTheme';
import { ConfirmProvider } from 'material-ui-confirm';
import './init/init';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <CustomMuiTheme>
          <ConfirmProvider>
            <LazyApp />
          </ConfirmProvider>
        </CustomMuiTheme>
      </Router>
    </Provider>
  </React.StrictMode>,
);
