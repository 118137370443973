const tokenKey = 'token';
const userKey = 'user';
const userType = 'userType';
const rolesKey = 'roles';
const refreshTokenKey = 'refreshToken';
const ipLocationKey = 'ipLocation';
const hashKey = 'hash';

export default class LocalCache {
  static saveHash(commit: string) {
    localStorage.setItem(hashKey, commit);
  }
  static fetchHash(): string | null {
    return localStorage.getItem(hashKey);
  }

  static saveToken(token: string) {
    localStorage.setItem(tokenKey, token);
  }

  static saveRefreshToken(token: string) {
    localStorage.setItem(refreshTokenKey, token);
  }

  static fetchToken(): string | null {
    return localStorage.getItem(tokenKey);
  }

  static fetchRefreshToken(): string | null {
    return localStorage.getItem(refreshTokenKey);
  }

  static saveUserType(userTypeStr: string) {
    localStorage.setItem(userType, userTypeStr);
  }
  static fetchUserType(): string | null {
    return localStorage.getItem(userType);
  }

  static saveUser(userJson: string) {
    localStorage.setItem(userKey, userJson);
  }

  static fetchUser(): string | null {
    return localStorage.getItem(userKey);
  }

  static fetchRoles(): string[] {
    return JSON.parse(localStorage.getItem(rolesKey) ?? '');
  }

  static clearCachedData(): void | null {
    return localStorage.clear();
  }

  static saveIPLocation(data: { lat: number; lng: number; country: string }) {
    return localStorage.setItem(ipLocationKey, JSON.stringify(data));
  }
  static fetchIPLocation(): { lat: number; lng: number; country: string } | null {
    try {
      return JSON.parse(localStorage.getItem(ipLocationKey)!);
    } catch {
      return null;
    }
  }

  static setTheme(string: 'light' | 'dark') {
    localStorage.setItem('theme', string);
  }
  static getTheme(): 'light' | 'dark' | null {
    return localStorage.getItem('theme') as 'light' | 'dark' | null;
  }
}
