import { TransactionTypes } from './User';

const creditMessage = 'Adjustment';
const debitMessage = 'Amount paid to Partner';

export const getDemoTransactionData = () => {
  return {
    accountBalance: -680460,
    creditAmount: 83000,
    debitAmount: 763460,
    transactionResponseDtos: [
      {
        creationDate: new Date('2021-12-14T14:04:47'),
        transactionAmount: 860,
        transactionId: 204,
        transactionMessage: debitMessage,
        transactionType: TransactionTypes.Debit,
      },
      {
        creationDate: new Date('2021-12-14T14:04:47'),
        transactionAmount: 306800,
        transactionId: 205,
        transactionMessage: debitMessage,
        transactionType: TransactionTypes.Debit,
      },
      {
        creationDate: new Date('2021-12-14T14:04:50'),
        transactionAmount: 54000,
        transactionId: 206,
        transactionMessage: debitMessage,
        transactionType: TransactionTypes.Debit,
      },
      {
        creationDate: new Date('2021-12-15T14:04:47'),
        transactionAmount: 318000,
        transactionId: 207,
        transactionMessage: 'Pending Payment',
        transactionType: TransactionTypes.Debit,
      },
      {
        creationDate: new Date('2021-12-16T14:04:47'),
        transactionAmount: 28000,
        transactionId: 208,
        transactionMessage: creditMessage,
        transactionType: TransactionTypes.Credit,
      },
      {
        creationDate: new Date('2021-12-17T14:04:47'),
        transactionAmount: 55000,
        transactionId: 209,
        transactionMessage: creditMessage,
        transactionType: TransactionTypes.Credit,
      },
      {
        creationDate: new Date('2021-12-18T14:04:47'),
        transactionAmount: 83800,
        transactionId: 210,
        transactionMessage: 'Pending Payment',
        transactionType: TransactionTypes.Debit,
      },
    ],
  };
};
