import { format } from 'date-fns';
import dayjs, { type Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { PIN_LENGTH } from './Constants';

dayjs.extend(utc);

export type DurationType = {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
};

export class DateTimeUtils {
  static dateTimeDisplayFormat = 'dd/MM/yyyy hh:mm a';
  static dateTimeDisplayFormatMomentNow = 'dd MMMM, yyyy hh:mm a';
  static dateDisplayFormatPST = 'dd/MM/yyyy';

  public static getDayName(date: Date, short?: boolean): string {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    return short ? days[date.getDay()].substring(0, 3) : days[date.getDay()];
  }

  public static getMonthName(date: Date, short?: boolean): string {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    return short ? months[date.getMonth()].substring(0, 3) : months[date.getMonth()];
  }

  public static getWeekNumber(date: Date): string {
    const d = new Date(date);
    d.setHours(0, 0, 0, 0);
    d.setDate(d.getDate() + 4 - (d.getDay() || 7));
    const yearStart = new Date(d.getFullYear(), 0, 1);
    const weekNo = Math.ceil(((d.valueOf() - yearStart.valueOf()) / 86400000 + 1) / 7);
    return weekNo.toString();
  }

  public static toDisplayableStringFromModelDate(date: Date | undefined): string {
    const newDate = this.toDateFromModelDate(date);
    return newDate ? newDate.toLocaleString().toUpperCase() : '';
  }

  public static toDateFromModelDate(date?: Date | string): Date | undefined {
    // The backend date trims timezone `Z` from utc date. Assuming UTC.
    return (date && this.toUTCDate(date).toDate()) || undefined;
  }

  public static toUTCDate(date: Date | string): Dayjs {
    return dayjs.utc(date);
  }

  public static isBeforeNow(date: Date) {
    const diffInMilliseconds = date.valueOf() - new Date().valueOf();
    return diffInMilliseconds <= 0;
  }

  public static toDisplayableString(isoDateString: string | undefined): string {
    if (isoDateString !== undefined) {
      return this.toDisplayableStringFromModelDate(new Date(isoDateString));
    }

    return '';
  }

  public static isTimeout(endDate: Date): boolean {
    const diffInMilliseconds = endDate.valueOf() - new Date().valueOf();
    return diffInMilliseconds <= 0;
  }

  public static toSplitTimeInDisplayableString(isoDateString: string | undefined): string {
    if (isoDateString !== undefined) {
      return isoDateString.split(',')[1];
    }
    return '';
  }

  public static toSplitDateInDisplayableString(isoDateString: string | undefined): string {
    if (isoDateString !== undefined) {
      return isoDateString.split(',')[0];
    }
    return '';
  }

  public static toModelDateTimeString(date: Date | Dayjs) {
    return date.toISOString();
  }

  public static convertToDuration(milliseconds: number): DurationType {
    const sign = Math.sign(milliseconds);
    milliseconds = Math.abs(milliseconds);
    const totalSeconds = Math.floor(milliseconds / 1000);
    const totalMinutes = Math.floor(totalSeconds / 60);
    const totalHours = Math.floor(totalMinutes / 60);
    const days = Math.floor(totalHours / 24) * sign;

    const seconds = (totalSeconds % 60) * sign;
    const minutes = (totalMinutes % 60) * sign;
    const hours = (totalHours % 24) * sign;

    return { days, hours, minutes, seconds };
  }
  public static getDisplayableCurrentDateTime = (): string | undefined => {
    return format(new Date(), DateTimeUtils.dateTimeDisplayFormatMomentNow);
  };

  public static getDisplayablePSTDateTime = (date: string | Date): string => {
    return format(new Date(date), DateTimeUtils.dateDisplayFormatPST);
  };

  public static isLoadingTimeValid(value?: Date | string | null): boolean {
    const yesterday = new Date(Date.now() - 24 * 60 * 60 * 1000); // that is: 24 * 60 * 60 * 1000
    if (dayjs(value).isAfter(yesterday)) return true;
    else return false;
  }
}

export function isValidEmail(email: string): boolean {
  const regex = new RegExp('^[a-zA-Z]+[a-zA-Z0-9._-]*@{1}[a-zA-Z0-9]+?(.{1}[a-zA-Z]{2,3})+$');
  return regex.test(email);
}
export function isValidVehicleRegistrationNum(VehicleRegistration: string): boolean {
  const result = /^[a-zA-Z]{1,10}-\d{1,10}$/;
  return result.test(VehicleRegistration);
}

export const isValidPassword = (password: string | undefined) => {
  const passwordRgex = new RegExp('^(?=.*[0-9])(?=.*[A-Za-z])(?=\\S+$).{8,}$');
  return passwordRgex.test(password ?? '');
};

export function isNumeric(value: string) {
  // eslint-disable-next-line no-useless-escape
  return /^\+?\-?\d+$/.test(value);
}

export function isValidPIN(pin?: string) {
  // eslint-disable-next-line no-useless-escape
  return pin?.length === PIN_LENGTH && /^\+?\-?\d+$/.test(pin);
}

export function isCNICValid(value: string) {
  return value.length == 13;
}
export function isNTNValid(value: string) {
  return value.length == 7;
}
export function phoneNumberValidation(phoneNumber: string): boolean {
  //*** Number Should be 11 digits(03211234567) & number should be started with 03 */
  const phoneFormatRegEx = /^03[0-9]{9}$/;
  if (phoneNumber?.length != 11) {
    throw new Error('The phone number must have 11 digits');
  } else if (!phoneFormatRegEx.test(phoneNumber)) {
    throw new Error('The phone number should start with 03');
  }
  return true;
}

export const convertToTonnes = (weightInKgs: number | any): number | any => {
  const weight = weightInKgs ? weightInKgs / 1000 : null;
  return Number(Number(weight).toFixed(2));
};

export const convertToKgs = (weightInTonnes: number | any): number | any => {
  const weight = weightInTonnes ? weightInTonnes * 1000 : null;
  return Number(Number(weight).toFixed(2));
};

export const pad = (number: number | string) => {
  const num = Number(number);
  return `${num >= 0 && num < 10 ? '0' : ''}${num}`;
};

export const setNumber = (number: string, min?: number, max?: number) => {
  let num = Number(number);
  num = isNaN(num) ? 0 : num;
  if (typeof min !== 'undefined' && num < min) num = min;
  if (typeof max !== 'undefined' && num > max) num = max;
  return num;
};

export const convertToFriendlyName = (status: string | undefined): string | undefined => {
  if (status === undefined) {
    return 'N/A';
  }
  return status[0] + status.substring(1).toLowerCase().replace(/_/gi, ' ');
};
export const convertToCapitalizedString = (text: string | undefined): string | undefined => {
  return !text ? text : text[0].toUpperCase() + text.substring(1).toLowerCase().replace(/_/gi, ' ');
};
export const range = (start: number, end: number) =>
  Array(end - start + 1)
    .fill(undefined)
    .map((_n, i) => i + start);
