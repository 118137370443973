import { TLocation } from 'src/management/Locations/Locations';
import { Booking } from '../booking/Booking';
import { Api } from '../common/Api';
import { PAKISTAN_COUNTRY_ID, apiResources } from '../common/Constants';
import {
  DateTimeUtils,
  isCNICValid,
  isNTNValid,
  isNumeric,
  isValidEmail,
  isValidPassword,
} from '../common/DataFormatUtils';
import { OrType } from '../common/types/UtilityTypes.type';
import { isPhoneNumberValid } from '../common/utils/phoneNumber.util';
import { getLoggedInUser, isDemandAddaUser } from '../management/User';
import { GOODS_TYPE_ENUM } from './Lookup';
import ShipmentLocationHelper from './ShipmentLocationHelper';

export enum ShipmentStatus {
  CREATED = 1,
  DISPATCH_STARTED = 2,
  TRANSIT = 3,
  DELIVERED = 4,
  CANCELLED = 5,
}

export enum ShipmentBidStatus {
  CREATED = 'CREATED',
  ACCEPTED = 'ACCEPTED',
  ACKNOWLEDGED = 'ACKNOWLEDGED',
  BACKOUT = 'BACKOUT',
  DRIVER_ASSIGNED = 'DRIVER_ASSIGNED',
  REJECTED = 'REJECTED',
  FULFILLED = 'FULFILLED',
}

export enum RefusalReasonTypes {
  BACKOUT_BID = 1,
  REJECT_BID = 2,
  CANCEL_BOOKING = 3,
}

enum ProjectVerticalTypes {
  LONG_HAUL,
  SHORT_HAUL,
  CROSS_BORDER,
  LTL,
  DA_DIGITIZATION,
  NORTH_LONG_HAUL,
  NORTH_SHORT_HAUL,
  NORTH_CROSS_BORDER,
  NORTH_LTL,
  NORTH_DA_DIGITIZATION,
  CENTRAL_PUNJAB_LONG_HAUL,
  CENTRAL_PUNJAB_SHORT_HAUL,
  CENTRAL_PUNJAB_CROSS_BORDER,
  CENTRAL_PUNJAB_LTL,
  CENTRAL_PUNJAB_DA_DIGITIZATION,
  SOUTH_PUNJAB_LONG_HAUL,
  SOUTH_PUNJAB_SHORT_HAUL,
  SOUTH_PUNJAB_CROSS_BORDER,
  SOUTH_PUNJAB_LTL,
  SOUTH_PUNJAB_DA_DIGITIZATION,
  SOUTH_LONG_HAUL,
  SOUTH_SHORT_HAUL,
  SOUTH_CROSS_BORDER,
  SOUTH_LTL,
  SOUTH_DA_DIGITIZATION,
}

export interface RefusalReason {
  id: number;
  reason: string;
}

export enum ShipmentPoolTypes {
  PUBLIC = 1,
  PRIVATE = 2,
}

export type ShipmentLocations = (PickUpDetails | DropOffDetails)[];

export interface Shipment {
  shipmentId: number;
  /** This is projectId
   * @deprecated
   */
  companyId: number;
  companyName: string;
  consigneeName: string;
  creationDateTime: Date;
  dropoffAddress: string;
  dropoffCity: string;
  goodsType: string;
  numberOfVehicles: number;
  // Only used while updating, API Issue
  noOfVehicles?: number;

  acceptAutoBid?: boolean;
  autoNegotiate?: boolean;

  pickupAddress: string;
  pickupCity: string;
  pointOfContactName: string;
  pointOfContactNumber: string;
  shipmentStatus: string;
  shipperId: number;
  shipperName: string;
  totalBids: number;
  vehicleType: string;
  vehicleTypeVariant: string;
  weight: number;
  createdBy: string;
  totalPages: number;
  isTaggable: boolean;
  tagId: ShipmentTag;
  paymentReceivingType: string;
  loadingTime?: Date;
  bidWindowStartDateTime?: Date;
  bidWindowEndDateTime?: Date;
  loadingWindowType: string;
  bookings: string;
  dropoff: string;
  goods: string;
  pickup: string;
  vehicle: string;
  shipmentCategoryTypeId: number;
  bidCategoryTypeId: number;
  minWeightPerVehicle: number;
  maxWeightPerVehicle: number;
  contractAmount: number;
  minShipperRate: number;
  maxShipperRate: number;
  paymentMargin?: number;
  cityTravelRestrictionTypeId: number;
  consigneeNumber: string;
  dimension: string;
  dropoffCityId: number;
  dropoffLatitude: number;
  dropoffLongitude: number;
  dropoffType: number;
  goodsTypeId: number;
  verificationStatusTypeId: number;
  isFraud: boolean; //TODO: remove and use verificationStatusTypeId everywhere
  fraudReasonTypeId?: number;
  fraudReason?: string;
  labourAvailabilityTypeId: number;
  loadingWindowTypeId: number;
  packaging: string;
  paymentReceivingTypeId: number;
  pickupCityId: number;
  pickupLatitude: number;
  pickupLongitude: number;
  pickupType: number;
  specialInstructions: string;
  vehicleSize: string;
  vehicleTypeId: number;
  vehicleTypeVariantId: number;
  bookingResponseV3Dtos: Booking[]; // Todo: This variable name needs to change!
  assignedToId: number;
  assignedToFullName: string;
  isFavourite: boolean;
  shipmentContainerDto?: ShipmentContainerDto;
  totalco2Emission?: any;
  travelledDistance?: any;
  poolType: ShipmentPoolTypes;
  projectVerticalType: ProjectVerticalTypes;
  projectVerticalTypeName: string;
  companyGroupName: string;
  shipperRate: number;
  shipmentLocations: ShipmentLocations;
  referenceNo: string;
}

export interface TrackingStatusDataRow {
  id?: number;
  shipmentStatus: string;
  shipmentStatusId: number;
  creationTime?: Date;
}

export interface ShipmentCompanyGroup {
  address: string;
  cityId: number;
  cityName: string;
  provinceId: number;
  countryId: number;
  countryName: string;
  id: number;
  identificationTypeId: number;
  identificationValue: string;
  logo: any;
  logoId: number;
  logoUrl: string;
  name: string;
  operationalCities?: number[] | null;
  totalCompanies: string;
  totalPages: number;
  countryRegistrationCertificate: string;
  importExportLicense: string;
}

export interface ShipmentCompanyProject {
  address: string;
  cityId: number;
  cityName: string;
  countryId: number;
  defaultUserId: number;
  email: string;
  id: number;
  identificationTypeId: number;
  identificationValue: string;
  isBlocked: boolean;
  logoId: number;
  logoUrl: string;
  marginTypeId: 1;
  marginValue: number;
  name: string;
  operationalCities: number[];
  phoneNumber: string;
  provinceId: number;
  strn: string;
  totalPages: number;
  groupId: number;
  groupName: string;
  isContractualAllowed: boolean;
  projectVerticalType: ProjectVerticalTypes;
  projectVerticalTypeName: string;
}

interface NewShipperCompanyGroupArgs {
  name: string;
  country: number;
  province: number;
  city: number;
  address: string;
  logo?: File;
  operationalCities?: number[] | null;
  identificationType: number;
  identificationValue: string;
  countryRegistrationCertificate?: string;
  importExportLicense?: string;
}

interface NewShipperCompanyProjectArgs {
  name: string;
  email: string;
  phoneNumber: string;
  marginType?: number | null;
  marginValue?: number | null;
  strn?: string | null;
  groupId: number;
  password: string;
  confirmPassword: string;
  allowContractualShipment: boolean;
  projectVerticalTypeId: ProjectVerticalTypes;
  countryId: number;
}

type UpdateShipperCompanyProjectArgs = Omit<NewShipperCompanyProjectArgs, 'password' | 'confirmPassword' | 'countryId'>;

export interface NewShipment {
  companyId: number;
  isPodHardCopyReq?: boolean;
  acceptAutoBid?: boolean;
  autoNegotiate?: boolean;
  pointOfContactName: string;
  pointOfContactNumber: string;
  shipperId: number;
  weight: number;
  shipmentCategoryTypeId: number;
  bidCategoryTypeId: number;
  minWeightPerVehicle: number;
  maxWeightPerVehicle: number;
  contractAmount: number;
  shipperRate: number;
  minShipperRate: number;
  maxShipperRate: number;
  addaMarfat: string;
  verificationStatusTypeId?: number;
  vehicleCount: number;
  consigneeName: string;
  consigneeNumber: string;
  vehicleTypeId: number;
  vehicleTypeVariantId: number;
  cityTravelRestrictionTypeId: number;
  labourAvailabilityTypeId: number;
  paymentReceivingTypeId: number;
  loadingTime?: string;
  specialInstructions: string;
  quantity: number;
  shipmentContainerDto?: NewShipmentContainerDto;
  poolType: ShipmentPoolTypes;
  referenceNumber?: string;
  customAddress?: boolean;
  hsCode?: boolean;
}

interface ShipmentLoad {
  id: number;
  weight: number;
  goodsTypeId: number;
  goodsType: string;
  goodsTypeDescription: string;
  goodsCodeType: GOODS_TYPE_ENUM;
}

export enum LocationActionType {
  PICK_UP = 1,
  DROP_OFF,
}

export interface DropOffDetails {
  id: number;
  pointOfContactName?: string;
  pointOfContactNumber?: string;
  cityId: number;
  cityName: string;
  address: string;
  latitude: number;
  longitude: number;
  locationType: 1; // not used anywhere rn
  locationTypeName: string;
  locationActionType: LocationActionType.DROP_OFF;
  locationActionTypeName: string;
  loads: ShipmentLoad[];
  note?: string;
  customLocation?: TLocation;
  zipCode: string;
}

export interface PickUpDetails extends Omit<DropOffDetails, 'locationActionType'> {
  locationActionType: LocationActionType.PICK_UP;
}

export type NewShipmentLoad = Partial<Omit<ShipmentLoad, 'id' | 'goodsCodeType'>>;

export type NewDropOffDetails = Partial<
  Omit<DropOffDetails, 'id' | 'loads' | 'locationTypeName' | 'locationActionTypeName'> & {
    loads: NewShipmentLoad[];
  }
>;

export type NewPickUpDetails = Partial<
  Omit<PickUpDetails, 'id' | 'loads' | 'locationTypeName' | 'locationActionTypeName'> & {
    loads: NewShipmentLoad[];
  }
>;

/** Allow `null` as default value for `react-hook-form`. */
export type EmptyNewShipment = OrType<
  NewShipment &
    Partial<Pick<Shipment, 'shipmentId'>> & {
      company: ShipmentCompanyGroup;
      project: ShipmentCompanyProject;
      dropOffLocations: NewDropOffDetails[];
      pickupLocations: NewPickUpDetails[];
    },
  null
>;

export type UpdateShipmentDto = Required<Pick<EmptyNewShipment, 'shipmentId'>> &
  Partial<Pick<Shipment, 'noOfVehicles'>> &
  Partial<
    Pick<
      EmptyNewShipment,
      | 'companyId'
      | 'shipperId'
      | 'paymentReceivingTypeId'
      | 'acceptAutoBid'
      | 'autoNegotiate'
      | 'shipperRate'
      | 'maxShipperRate'
      | 'minShipperRate'
      | 'contractAmount'
      | 'vehicleTypeId'
      | 'vehicleTypeVariantId'
      // | 'vehicleCount'
      | 'weight'
      | 'loadingTime'
      | 'specialInstructions'
      | 'shipmentContainerDto'
      | 'maxWeightPerVehicle'
      | 'minWeightPerVehicle'
      // Unwanted Details 👇
      | 'consigneeName'
      | 'consigneeNumber'
      | 'labourAvailabilityTypeId'
      | 'cityTravelRestrictionTypeId'
      | 'bidCategoryTypeId'
    > & {
      pickupLocations: EmptyNewShipment['pickupLocations'];
      dropOffLocations: EmptyNewShipment['dropOffLocations'];
    }
  >;

interface NewShipmentContainerDto {
  blNumber: string;
  emptyReturnDate: string | null;
  noOfContainers: number;
  detentionCharges: number;
  returnLocation: string;
}

interface ShipmentContainerDto {
  blNumber: string;
  emptyReturnDate: Date | null;
  noOfContainers: number;
  detentionCharges: number;
  returnLocation: string;
}

interface BidCounterOffer {
  bidAmount: number;
  totalAmount: number;
  sellingAmount: number;
  totalSelling: number;
  offeredWeight: number;
  noOfVehicles: number;
}

export interface ShipmentBid {
  shipmentId: number;
  truckerId: number;
  truckerFullName: string;
  isTruckerVerified: boolean;
  truckerPhone: string;
  truckerTrips: number;
  noOfVehicles: number;
  ratePerUnit: number;
  bidAmount: number;
  totalAmount: number;
  eta: Date;
  bidStatus: ShipmentBidStatus;
  max: number;
  bidId: number;
  userVerificationStatusName?: string;
  marginProtected: boolean;
  bidCategoryTypeId: number;
  noOfTonnes?: number;
  tonnePerVehicle?: string;
  bidStatusId?: number;
  minWeightPerVehicle?: number;
  maxWeightPerVehicle?: number;
  tinMarginPercentage?: number;
  buying?: number;
  bidCounterOffer?: BidCounterOffer;
  sellingAmount: number;
}
export interface ShipmentVerificationStatus {
  bookingId: number;
  vehicleVerified: boolean;
  builtyVerified: boolean;
  podverified: boolean;
  driverVerified: boolean;
  partnerVerified: boolean;
}

export const DEFAULT_SHIPMENT_LOAD: NewShipmentLoad = {
  goodsTypeDescription: '',
  goodsTypeId: undefined,
  weight: undefined,
};

export const DEFAULT_DROPOFF_DETAIL: NewDropOffDetails = {
  cityId: undefined,
  address: '',
  latitude: undefined,
  longitude: undefined,
  locationType: 1,
  locationActionType: LocationActionType.DROP_OFF,
  loads: [DEFAULT_SHIPMENT_LOAD],
};

export const DEFAULT_PICKUP_DETAIL: NewPickUpDetails = {
  cityId: undefined,
  address: '',
  latitude: undefined,
  longitude: undefined,
  locationType: 1,
  locationActionType: LocationActionType.PICK_UP,
  loads: [],
};

export function createEmptyHookShipment(): EmptyNewShipment {
  return {
    company: null,
    project: null,

    acceptAutoBid: false,
    autoNegotiate: false,
    bidCategoryTypeId: null,
    consigneeName: '',
    consigneeNumber: '',
    contractAmount: null,
    isPodHardCopyReq: null,
    loadingTime: null,
    shipperRate: null,
    maxShipperRate: null,
    maxWeightPerVehicle: 0,
    minShipperRate: null,
    minWeightPerVehicle: 0,
    paymentReceivingTypeId: null,
    pointOfContactName: '',
    pointOfContactNumber: '',
    shipmentCategoryTypeId: null,
    specialInstructions: '',
    vehicleCount: 1,
    vehicleTypeId: null,
    vehicleTypeVariantId: null,
    weight: null,

    labourAvailabilityTypeId: 1,
    cityTravelRestrictionTypeId: 1,
    addaMarfat: '',

    companyId: null,
    shipperId: null,
    quantity: 0,

    shipmentContainerDto: {
      blNumber: '',
      detentionCharges: null,
      emptyReturnDate: '',
      noOfContainers: null,
      returnLocation: '',
    },

    /** Demand Adda */
    poolType: ShipmentPoolTypes.PUBLIC,

    dropOffLocations: [DEFAULT_DROPOFF_DETAIL],
    pickupLocations: [DEFAULT_PICKUP_DETAIL],

    customAddress: false,
    hsCode: false,
  };
}

export async function populateHookValues(shipment: Shipment): Promise<EmptyNewShipment> {
  const companyProject = await getShipperCompanyProject(shipment.companyId);
  const companyGroup = await fetchCompanyGroupById(companyProject.groupId);
  return {
    company: companyGroup || null,
    project: companyProject || null,

    shipmentId: shipment.shipmentId || null,
    acceptAutoBid: Boolean(shipment.acceptAutoBid),
    autoNegotiate: Boolean(shipment.autoNegotiate),
    bidCategoryTypeId: shipment.bidCategoryTypeId || null,
    consigneeName: shipment.consigneeName || '',
    companyId: companyProject.id || null,
    consigneeNumber: shipment.consigneeNumber || '',
    isPodHardCopyReq: null,
    loadingTime:
      shipment.loadingTime && DateTimeUtils.isLoadingTimeValid(shipment.loadingTime)
        ? (shipment.loadingTime as any)
        : null,
    shipperRate: shipment.shipperRate || null,
    maxShipperRate: (shipment.maxShipperRate ?? shipment.minShipperRate) || 0,
    minShipperRate: shipment.minShipperRate || 0,
    paymentReceivingTypeId: shipment.paymentReceivingTypeId ?? null,
    pointOfContactName: shipment.consigneeName || '',
    pointOfContactNumber: shipment.consigneeNumber || '',
    shipmentCategoryTypeId: shipment.shipmentCategoryTypeId || null,
    specialInstructions: shipment.specialInstructions || '',
    vehicleCount: shipment.numberOfVehicles || 1,
    vehicleTypeId: shipment.vehicleTypeId || null,
    vehicleTypeVariantId: shipment.vehicleTypeVariantId || null,
    weight: shipment.weight || null,

    labourAvailabilityTypeId: shipment.labourAvailabilityTypeId || 1,
    cityTravelRestrictionTypeId: shipment.cityTravelRestrictionTypeId || 1,
    addaMarfat: '',

    // TODO: Unwanted fields
    contractAmount: shipment.contractAmount || null,
    maxWeightPerVehicle: shipment.maxWeightPerVehicle || 0,
    minWeightPerVehicle: shipment.minWeightPerVehicle || 0,
    quantity: 0,
    shipperId: shipment.shipperId || null,
    verificationStatusTypeId: shipment.verificationStatusTypeId || null,

    shipmentContainerDto: {
      blNumber: shipment.shipmentContainerDto?.blNumber || '',
      detentionCharges: shipment.shipmentContainerDto?.detentionCharges || null,
      emptyReturnDate: (shipment.shipmentContainerDto?.emptyReturnDate as any) || null,
      noOfContainers: shipment.shipmentContainerDto?.noOfContainers || null,
      returnLocation: shipment.shipmentContainerDto?.returnLocation || '',
    },

    /** Demand Adda User */
    poolType: shipment.poolType,

    dropOffLocations: ShipmentLocationHelper.filterDropOffLocations(shipment.shipmentLocations),
    pickupLocations: ShipmentLocationHelper.filterPickupLocations(shipment.shipmentLocations),
    referenceNumber: shipment.referenceNo || null,
    hsCode: ShipmentLocationHelper.areLoadsHSCode(shipment.shipmentLocations),
  };
}

export enum ErrorMessage {
  None = '',
  AllFieldsRequired = 'Please fill in all required fields',
  InvalidLoadingTime = 'Loading time must be greater than current date and time',
  InvalidReturnTime = 'Return time must be greater than Loading time!',
  InvalidQuantity = 'Quantity should be between 1 and 10 (inclusive)',
  InvalidWeight = 'Weight should be between 1 and 10000 (inclusive)',
  InvalidInstructions = 'Instructions must be less than or equal to 1000 characters',
  PasswordsUnmatched = 'Passwords must match',
  PasswordsValidation = 'Passwords must have at least 8 characters with at least one alphabet and one number.',
  PINValidation = `PIN must be digits with a length of `,
  PINsUnmatched = 'PINs must match',
  InvalidEmail = 'Email address is not valid',
  InvalidPhoneNUmber = 'Phone number is not valid',
  SamePickupDropoffCities = 'Please select different pick up and drop off cities.',
  InvalidContractAmount = 'Please enter valid contract amount.',
  InvalidShipmentType = 'Please select valid Shipment Category.',
  PickupAddressLimit = 'Pick up address should be less than 300 characters.',
  DropoffAddressLimit = 'Drop off address should be less than 300 characters.',
  MinWeight = 'Min should be less than max weight',
  TotalWeight = 'Total shipment weight should not greater than max and less than min vehicles weight.',
  InvalidNumber = 'Number should be greater than 0',
  CNICLengthValid = 'Cnic should be 13 digits long',
  CNICNumberValid = 'Cnic should be a number',
  NTNLengthValid = 'Ntn should be 7 digits long',
  NTNNumberValid = 'Ntn should be a number',
}

export interface FetchFavoriteShipmentsArgs {
  createdByMe: boolean;
  startDate: string | null;
  endDate: string | null;
  page: number;
  pageSize?: number;
  searchParam?: { [key: string]: string };
}
export interface FetchShipmentsArgs {
  types?: ShipmentStatus[];
  createdByMe: boolean;
  assignedToMe?: boolean;
  startDate: string | null;
  endDate: string | null;
  page: number;
  pageSize?: number;
  searchParam?: { [key: string]: string };
  tagId: string;
  paymentModeTypeIds?: any;
  goodsTypeId?: any;
  privateLoadboard?: boolean;
  companyGroupIds?: any;
  referenceNumber: string | null;
}
class ShipmentApi {
  private readonly api = new Api();

  async assignShipmentToInternalPOC(shipmentId: number, userId: number) {
    return await this.api.createResource(`${apiResources.shipment}/${shipmentId}/poc/${userId}/assign`);
  }

  async changeShipmentVerticalType(shipmentId: number, verticalTypeId: ProjectVerticalTypes) {
    return await this.api.createResource(`${apiResources.shipment}/${shipmentId}/project-vertical/${verticalTypeId}`);
  }

  async fetchShipmentBookings(shipmentId: number): Promise<Shipment> {
    return await this.api.getResource(`${apiResources.shipment}/${shipmentId}/booking/v3`);
  }

  async fetchShipmentPricing(shipmentId: number): Promise<Shipment> {
    return await this.api.getResource(`${apiResources.shipment}/${shipmentId}/booking/pricing-info`);
  }

  async shipmentTagged(shipmentId: number): Promise<Shipment> {
    return await this.api.createResource(`${apiResources.shipment}/${shipmentId}/tag/1`);
  }

  async shipmentUnTagged(shipmentId: number): Promise<Shipment> {
    return await this.api.createResource(`${apiResources.shipment}/${shipmentId}/untag`);
  }

  async updatePool(shipmentId: number, poolType: ShipmentPoolTypes.PUBLIC) {
    await this.api.updatePartialResource(`${apiResources.shipment}/${shipmentId}/change-pool`, {
      poolType,
    });
  }

  async updateContractAmount(shipmentId: number, contractAmount: number) {
    await this.api.updatePartialResource(`${apiResources.shipment}/${shipmentId}/margin`, {
      contractAmount: contractAmount,
    });
  }

  async concludeShipment(shipmentId: number) {
    return await this.api.createResource(`${apiResources.shipment}/${shipmentId}/decrement/vehicle-count`, {});
  }
  async shipmentVerificationStatus(shipmentId: number): Promise<ShipmentVerificationStatus[]> {
    return await this.api.getResource(`${apiResources.verfication}/shipment/${shipmentId}/`, {});
  }
  async fetchCompanyProjects(
    page = 0,
    searchParam?: { [key: string]: string },
    pageSize = 20,
  ): Promise<ShipmentCompanyProject[]> {
    return await this.api.getResource(`${apiResources.endpoint}/company/v2`, {
      page,
      pageSize,
      ...searchParam,
    });
  }
  async fetchCompanyGroups(
    page = 0,
    searchParam?: { [key: string]: string },
    pageSize = 20,
  ): Promise<ShipmentCompanyGroup[]> {
    return await this.api.getResource(`${apiResources.endpoint}/company-group/`, {
      page,
      pageSize,
      ...searchParam,
    });
  }

  async fetchCompanyProjectByPhoneNumber(phoneNumber: string): Promise<ShipmentCompanyProject[]> {
    return await this.api.getResource(`${apiResources.endpoint}/company/v2`, {
      page: 0,
      pageSize: 20,
      personUsername: phoneNumber,
    });
  }

  async fetchCompanyGroupById(id: number): Promise<ShipmentCompanyGroup> {
    return await this.api.getResource(`${apiResources.endpoint}/company-group/${id}`);
  }

  async fetchCompanyGroupProjects(companyGroupId: number): Promise<ShipmentCompanyGroup[]> {
    return await this.api.getResource(`${apiResources.endpoint}/company-group/${companyGroupId}/all`);
  }

  async createNewShipmentCompanyGroup(args: NewShipperCompanyGroupArgs) {
    const bodyPayload = [
      { key: 'name', value: args.name },
      { key: 'address', value: args.address },
      { key: 'cityId', value: args.city },
      { key: 'provinceId', value: args.province },
      { key: 'countryId', value: args.country },
      { key: 'identificationValue', value: args.identificationValue },
      { key: 'identificationType', value: args.identificationType },
      { key: 'companyRegistrationCertificate', value: args.countryRegistrationCertificate },
      { key: 'importExportLicense', value: args.importExportLicense },
    ];

    if (args.logo !== undefined) {
      bodyPayload.push({ key: 'logo', value: args.logo as any });
    }

    if (args.operationalCities) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
      for (let i = 0; i < args.operationalCities?.length!; i++) {
        bodyPayload.push({
          key: 'operationalCities',
          value: args.operationalCities?.[i],
        });
      }
    }

    return await this.api.postFormData(`${apiResources.endpoint}/company-group`, bodyPayload);
  }

  async createNewShipmentCompanyProject(args: NewShipperCompanyProjectArgs): Promise<any> {
    const bodyPayload = [
      { key: 'name', value: args.name },
      { key: 'email', value: args.email },
      { key: 'phoneNumber', value: args.phoneNumber },
      { key: 'strn', value: args.strn },
      { key: 'groupId', value: args.groupId },
      { key: 'isContractualAllowed', value: args.allowContractualShipment },
      { key: 'projectVerticalTypeId', value: args.projectVerticalTypeId },
      { key: 'countryId', value: args.countryId },
      { key: 'password', value: args.password },
    ];
    if (args.marginType) {
      bodyPayload.push({ key: 'marginTypeId', value: args.marginType });
    } else {
      bodyPayload.push({ key: 'marginTypeId', value: 0 });
    }
    if (args.marginValue) {
      bodyPayload.push({ key: 'marginValue', value: args.marginValue });
    }

    return await this.api.postFormData(`${apiResources.endpoint}/company/v2`, bodyPayload);
  }

  async updateShipmentCompanyGroup(id: number, args: NewShipperCompanyGroupArgs) {
    const bodyPayload = [
      { key: 'id', value: id },
      { key: 'name', value: args.name },
      { key: 'address', value: args.address },
      { key: 'cityId', value: args.city },
      { key: 'provinceId', value: args.province },
      { key: 'countryId', value: args.country },
      { key: 'identificationValue', value: args.identificationValue },
      { key: 'identificationType', value: args.identificationType },
      { key: 'companyRegistrationCertificate', value: args.countryRegistrationCertificate },
      { key: 'importExportLicense', value: args.importExportLicense },
    ];

    if (args.logo !== undefined) {
      bodyPayload.push({ key: 'logo', value: args.logo as any });
    }

    if (args.operationalCities) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
      for (let i = 0; i < args.operationalCities?.length!; i++) {
        bodyPayload.push({
          key: 'operationalCities',
          value: args.operationalCities?.[i],
        });
      }
    }
    return await this.api.putFormData(`${apiResources.endpoint}/company-group`, bodyPayload);
  }
  async updateShipmentCompanyProject(id: number, args: UpdateShipperCompanyProjectArgs): Promise<any> {
    const bodyPayload = [
      { key: 'id', value: id },
      { key: 'name', value: args.name },
      { key: 'email', value: args.email },
      { key: 'phoneNumber', value: args.phoneNumber },
      { key: 'strn', value: args.strn },
      { key: 'groupId', value: args.groupId },
      { key: 'isContractualAllowed', value: args.allowContractualShipment },
      { key: 'projectVerticalTypeId', value: args.projectVerticalTypeId },
    ];
    if (args.marginType) {
      bodyPayload.push({ key: 'marginTypeId', value: args.marginType });
    } else {
      bodyPayload.push({ key: 'marginTypeId', value: 0 });
    }
    if (args.marginValue) {
      bodyPayload.push({ key: 'marginValue', value: args.marginValue });
    }

    return await this.api.putFormData(`${apiResources.endpoint}/company/v2`, bodyPayload);
  }

  async updateTimeWindowByShipmentId(shipmentId: number | undefined, NoOfHours: number | undefined) {
    await this.api.updateResource(`${apiResources.shipment}/${shipmentId}/bid/window/${NoOfHours}/v3`, {});
  }

  async openShipmentBidding(shipmentId: number) {
    await this.api.createResource(`${apiResources.shipment}/${shipmentId}/bid/window/action?action=open`);
  }

  async closeShipmentBidding(shipmentId: number) {
    await this.api.createResource(`${apiResources.shipment}/${shipmentId}/bid/window/action?action=close`);
  }

  async deleteShipperCompanyGroup(shipperCompanyGroupId: number): Promise<string> {
    return await this.api.deleteResource(`${apiResources.endpoint}/company-group/${shipperCompanyGroupId}`);
  }

  async deleteShipperCompanyProject(shipperCompanyProjectId: number): Promise<string> {
    return await this.api.deleteResource(`${apiResources.endpoint}/company/${shipperCompanyProjectId}`);
  }
  async getShipperCompanyProject(shipperCompanyProjectId: number): Promise<ShipmentCompanyProject> {
    return await this.api.getResource(`${apiResources.endpoint}/company/${shipperCompanyProjectId}`);
  }

  async getTrackingByShipmentId(shipmentId: number): Promise<TrackingStatusDataRow[]> {
    return await this.api.getResource(`${apiResources.shipmentTracking}/${shipmentId}/status/all`);
  }

  async getBidsByShipmentId(shipmentId: number): Promise<ShipmentBid[]> {
    return await this.api.getResource(`${apiResources.bid}/shipment/${shipmentId}/v3`);
  }
}

export async function fetchPricingByShipmentId(id: number): Promise<Shipment> {
  const shipmentApi = new ShipmentApi();
  return await shipmentApi.fetchShipmentPricing(id);
}

export async function assignShipmentToInternalPOCById(shipmentId: number, userId: number) {
  const shipmentApi = new ShipmentApi();
  return await shipmentApi.assignShipmentToInternalPOC(shipmentId, userId);
}

export async function changeShipmentVerticalType(shipmentId: number, verticalTypeId: ProjectVerticalTypes) {
  const shipmentApi = new ShipmentApi();
  return await shipmentApi.changeShipmentVerticalType(shipmentId, verticalTypeId);
}

export async function shipmentTaggedByOps(id: number): Promise<Shipment> {
  const shipmentApi = new ShipmentApi();
  return await shipmentApi.shipmentTagged(id);
}

export async function shipmentUnTaggedByOps(id: number): Promise<Shipment> {
  const shipmentApi = new ShipmentApi();
  return await shipmentApi.shipmentUnTagged(id);
}

export async function fetchCompanyProjects(
  page = 1,
  searchBy = '',
  searchKeyword = '',
  pageSize = 20,
): Promise<ShipmentCompanyProject[]> {
  const shipmentApi = new ShipmentApi();
  return shipmentApi.fetchCompanyProjects(page - 1, { [searchBy]: searchKeyword }, pageSize);
}

export async function fetchCompanyGroups(
  page = 1,
  searchBy = '',
  searchKeyword = '',
  pageSize = 20,
): Promise<ShipmentCompanyGroup[]> {
  const shipmentApi = new ShipmentApi();
  return shipmentApi.fetchCompanyGroups(page - 1, { [searchBy]: searchKeyword }, pageSize);
}

export async function fetchCompanyProjectByPhoneNumber(phoneNumber: string): Promise<ShipmentCompanyProject[]> {
  const shipmentApi = new ShipmentApi();
  return shipmentApi.fetchCompanyProjectByPhoneNumber(phoneNumber);
}

export async function fetchCompanyGroupById(id: number): Promise<ShipmentCompanyGroup> {
  const shipmentApi = new ShipmentApi();
  return shipmentApi.fetchCompanyGroupById(id);
}

export async function fetchCompanyGroupProjects(companyGroupId: number): Promise<any> {
  const shipmentApi = new ShipmentApi();
  return shipmentApi.fetchCompanyGroupProjects(companyGroupId);
}

export async function getTrackingByShipmentId(shipmentId: number): Promise<TrackingStatusDataRow[]> {
  const shipmentApi = new ShipmentApi();
  return shipmentApi.getTrackingByShipmentId(shipmentId);
}

export async function updateShipmentPool(shipmentId: number, poolType: ShipmentPoolTypes) {
  if (poolType === ShipmentPoolTypes.PRIVATE) {
    throw new Error('Cannot move shipment from public to private loadboard.');
  }
  const shipmentApi = new ShipmentApi();
  return await shipmentApi.updatePool(shipmentId, poolType);
}

export async function updateShipmentContractAmount(shipmentId: number, contractAmount: number) {
  const shipmentApi = new ShipmentApi();
  await shipmentApi.updateContractAmount(shipmentId, contractAmount);
}

export async function shipmentVerificationStatus(shipmentId: number): Promise<ShipmentVerificationStatus[]> {
  const shipmentApi = new ShipmentApi();
  return await shipmentApi.shipmentVerificationStatus(shipmentId);
}

export async function concludeShipment(shipmentId: number) {
  const shipmentApi = new ShipmentApi();
  return await shipmentApi.concludeShipment(shipmentId);
}
export async function getBidsByShipmentId(shipmentId: number) {
  const shipmentApi = new ShipmentApi();
  return shipmentApi.getBidsByShipmentId(shipmentId);
}

export async function createNewShipperCompanyGroup(args: NewShipperCompanyGroupArgs) {
  if (
    !args.address ||
    !args.name ||
    !args.city ||
    !args.province ||
    !args.country ||
    !args.identificationType ||
    !args.identificationValue
  ) {
    throw ErrorMessage.AllFieldsRequired;
  }
  if (args.country !== PAKISTAN_COUNTRY_ID && !args.importExportLicense) {
    throw ErrorMessage.AllFieldsRequired;
  }
  if (args.identificationType === 2) {
    if (!isCNICValid(args.identificationValue)) {
      throw ErrorMessage.CNICLengthValid;
    }
    if (!isNumeric(args.identificationValue)) {
      throw ErrorMessage.CNICNumberValid;
    }
  }
  if (args.identificationType == 1 && !isNumeric(args.identificationValue)) {
    if (!isNTNValid(args.identificationValue)) {
      throw ErrorMessage.NTNLengthValid;
    }
    if (!isNumeric(args.identificationValue)) {
      throw ErrorMessage.NTNNumberValid;
    }
  }

  const shipmentApi = new ShipmentApi();
  await shipmentApi.createNewShipmentCompanyGroup(args);
}

export async function createNewShipperCompanyProject(args: NewShipperCompanyProjectArgs) {
  let defaultValuesForDemandAdda = {};
  const loggedInUser = getLoggedInUser();
  if (isDemandAddaUser(loggedInUser)) {
    const companyGroups = await fetchCompanyGroups(1, 'personFullName', '');
    defaultValuesForDemandAdda = {
      password: 'dummypassword321',
      confirmPassword: 'dummypassword321',
      projectVerticalTypeId: ProjectVerticalTypes.LONG_HAUL,
      groupId: companyGroups[0].id,
      countryId: companyGroups[0].countryId,
    };
  }

  const newProject = { ...args, ...defaultValuesForDemandAdda };
  if (
    !newProject.name ||
    !newProject.groupId ||
    !newProject.phoneNumber ||
    !newProject.password ||
    !newProject.confirmPassword ||
    ((!newProject.strn || newProject.projectVerticalTypeId === undefined) && args.phoneNumber.startsWith('92'))
  ) {
    throw ErrorMessage.AllFieldsRequired;
  }
  if (newProject.email && !isValidEmail(newProject.email!)) {
    throw ErrorMessage.InvalidEmail;
  }
  if (!(await isPhoneNumberValid('+' + newProject.phoneNumber))) {
    throw ErrorMessage.InvalidPhoneNUmber;
  }
  if (!isValidPassword(newProject.password) && !isDemandAddaUser(getLoggedInUser())) {
    throw ErrorMessage.PasswordsValidation;
  }
  if (newProject.password !== newProject.confirmPassword) {
    throw ErrorMessage.PasswordsUnmatched;
  }

  const shipmentApi = new ShipmentApi();
  await shipmentApi.createNewShipmentCompanyProject(newProject);
}

export async function updateShipperCompanyGroup(id: number, args: NewShipperCompanyGroupArgs) {
  if (
    !args.address ||
    !args.name ||
    !args.city ||
    !args.province ||
    !args.country ||
    !args.identificationType ||
    !args.identificationValue
  ) {
    throw ErrorMessage.AllFieldsRequired;
  }
  if (args.country !== PAKISTAN_COUNTRY_ID && !args.importExportLicense) {
    throw ErrorMessage.AllFieldsRequired;
  }

  const shipmentApi = new ShipmentApi();
  await shipmentApi.updateShipmentCompanyGroup(id, args);
}
export async function updateShipperCompanyProject(id: number, args: UpdateShipperCompanyProjectArgs) {
  if (!args.name || !args.phoneNumber || !args.groupId || !args.strn || args.projectVerticalTypeId === undefined) {
    throw ErrorMessage.AllFieldsRequired;
  }

  const shipmentApi = new ShipmentApi();
  await shipmentApi.updateShipmentCompanyProject(id, args);
}

export async function updateTimeWindowByShipmentId(shipmentId: number | undefined, NoOfHours: number | undefined) {
  const api = new ShipmentApi();
  await api.updateTimeWindowByShipmentId(shipmentId, NoOfHours);
}

export async function openShipmentBidding(shipmentId: number) {
  const api = new ShipmentApi();
  await api.openShipmentBidding(shipmentId);
}

export async function closeShipmentBidding(shipmentId: number) {
  const api = new ShipmentApi();
  await api.closeShipmentBidding(shipmentId);
}

export async function deleteShipperCompanyGroup(shipperCompanyGroupId: number): Promise<string> {
  const api = new ShipmentApi();
  return await api.deleteShipperCompanyGroup(shipperCompanyGroupId);
}

export async function deleteShipperCompanyProject(shipperCompanyProjectId: number): Promise<string> {
  const api = new ShipmentApi();
  return await api.deleteShipperCompanyProject(shipperCompanyProjectId);
}

async function getShipperCompanyProject(shipperCompanyProjectId: number): Promise<ShipmentCompanyProject> {
  const api = new ShipmentApi();
  return await api.getShipperCompanyProject(shipperCompanyProjectId);
}

export enum ShipmentTag {
  NORMAL = 0,
  TAGGED_BY_ADDA = 1,
  DIGITIN = 2,
}

export enum ShipmentDurationType {
  Current = 'current',
  Past = 'past',
  All = 'all',
  Favourite = 'favourite',
}

export enum ShipmentType {
  SPOT = 1,
  CONTRACTUAL = 2,
  WHATSAPP = 3,
}

export enum ShipmentContractualType {
  PER_VEHICLE = 1,
  PER_TONNE = 2,
}

export const getShipmentTypes = (isContractualAllowed: boolean) => {
  const shipmentTypes = [
    { name: ShipmentType[1], value: 1, label: getLoggedInUser().preferences?.terms.shipmentTypes[0] || 'Spot' },
  ];
  const user = getLoggedInUser();
  if (isContractualAllowed)
    shipmentTypes.push({
      name: ShipmentType[2],
      value: 2,
      label: user.preferences?.terms.shipmentTypes[1] || 'Contractual',
    });
  return shipmentTypes;
};

export function ShipmentTypeLabels(): { [key: number]: string } {
  const user = getLoggedInUser();

  return {
    [ShipmentType.SPOT]: user.preferences?.terms.shipmentTypes[0] || 'Spot',
    [ShipmentType.CONTRACTUAL]: user.preferences?.terms.shipmentTypes[1] || 'Contract',
    [ShipmentType.WHATSAPP]: 'WhatsApp',
  };
}
export function shipmentSubTypeLabel(type: ShipmentContractualType) {
  switch (type) {
    case ShipmentContractualType.PER_TONNE:
      return `/Per ${getLoggedInUser().preferences.weightUnit}`;
    case ShipmentContractualType.PER_VEHICLE:
      return '/Per Vehicle';
  }
}

export enum PaymentType {
  PAID = 1,
  TO_PAY,
  ADVANCE,
}

export function paymentTypes() {
  const user = getLoggedInUser();
  return [
    { name: PaymentType[1], value: 1, label: user.preferences?.terms.paymentTypes[0] || 'Paid' },
    { name: PaymentType[2], value: 2, label: user.preferences?.terms.paymentTypes[1] || 'To Pay' },
  ];
}

export const getShipmentContractualTypes = () => [
  { name: ShipmentContractualType[1], value: 1, label: 'Per Vehicle' },
  { name: ShipmentContractualType[2], value: 2, label: 'Per ' + getLoggedInUser().preferences?.weightUnit || 'Tonne' },
];

export interface ProjectVerticalTypeOptionType {
  id: ProjectVerticalTypes;
  label: string;
}

export const ProjectVerticalTypeOptions: ProjectVerticalTypeOptionType[] = [
  { id: ProjectVerticalTypes.LONG_HAUL, label: 'Long Haul' },
  { id: ProjectVerticalTypes.SHORT_HAUL, label: 'Short Haul' },
  { id: ProjectVerticalTypes.CROSS_BORDER, label: 'Cross Border' },
  { id: ProjectVerticalTypes.LTL, label: 'LTL' },
  { id: ProjectVerticalTypes.DA_DIGITIZATION, label: 'DA Digitization' },
  { id: ProjectVerticalTypes.NORTH_LONG_HAUL, label: 'North Long Haul' },
  { id: ProjectVerticalTypes.NORTH_SHORT_HAUL, label: 'North Short Haul' },
  { id: ProjectVerticalTypes.NORTH_CROSS_BORDER, label: 'North Cross Border' },
  { id: ProjectVerticalTypes.NORTH_LTL, label: 'North LTL' },
  { id: ProjectVerticalTypes.NORTH_DA_DIGITIZATION, label: 'North DA Digitization' },
  { id: ProjectVerticalTypes.CENTRAL_PUNJAB_LONG_HAUL, label: 'Central Punjab Long Haul' },
  { id: ProjectVerticalTypes.CENTRAL_PUNJAB_SHORT_HAUL, label: 'Central Punjab Short Haul' },
  { id: ProjectVerticalTypes.CENTRAL_PUNJAB_CROSS_BORDER, label: 'Central Punjab Cross Border' },
  { id: ProjectVerticalTypes.CENTRAL_PUNJAB_LTL, label: 'Central Punjab LTL' },
  { id: ProjectVerticalTypes.CENTRAL_PUNJAB_DA_DIGITIZATION, label: 'Central Punjab DA Digitization' },
  { id: ProjectVerticalTypes.SOUTH_PUNJAB_LONG_HAUL, label: 'South Punjab Long Haul' },
  { id: ProjectVerticalTypes.SOUTH_PUNJAB_SHORT_HAUL, label: 'South Punjab Short Haul' },
  { id: ProjectVerticalTypes.SOUTH_PUNJAB_CROSS_BORDER, label: 'South Punjab Cross Border' },
  { id: ProjectVerticalTypes.SOUTH_PUNJAB_LTL, label: 'South Punjab LTL' },
  { id: ProjectVerticalTypes.SOUTH_PUNJAB_DA_DIGITIZATION, label: 'South Punjab DA Digitization' },
  { id: ProjectVerticalTypes.SOUTH_LONG_HAUL, label: 'South Long Haul' },
  { id: ProjectVerticalTypes.SOUTH_SHORT_HAUL, label: 'South Short Haul' },
  { id: ProjectVerticalTypes.SOUTH_CROSS_BORDER, label: 'South Cross Border' },
  { id: ProjectVerticalTypes.SOUTH_LTL, label: 'South LTL' },
  { id: ProjectVerticalTypes.SOUTH_DA_DIGITIZATION, label: 'South DA Digitization' },
];

export class ShipmentStatusHandler {
  public static shipmentStatuses = [
    'CREATED',
    'DISPATCH_STARTED',
    'TRANSIT',
    'DELIVERED',
    'CANCELLED',
    'PARTIALLY_FULFILLED',
  ];

  private static getStatusIndexes(status: string, targetStatus: string) {
    const statuses = ShipmentStatusHandler.shipmentStatuses;
    const currentIndex = statuses.indexOf(status);
    const targetIndex = statuses.indexOf(targetStatus);
    return [currentIndex, targetIndex];
  }

  static statusIsBefore(status: string, targetStatus: string) {
    const [currentIndex, targetIndex] = ShipmentStatusHandler.getStatusIndexes(status, targetStatus);
    return currentIndex < targetIndex;
  }

  static statusIsAfter(status: string, targetStatus: string) {
    const [currentIndex, targetIndex] = ShipmentStatusHandler.getStatusIndexes(status, targetStatus);
    return currentIndex > targetIndex;
  }

  static convertToNumber(status: string | undefined): number | -1 {
    return ShipmentStatusHandler.shipmentStatuses.indexOf(status ?? '');
  }

  static convertToStatus(index: number): string {
    return ShipmentStatusHandler.shipmentStatuses[index] ?? undefined;
  }

  static convertToFriendlyName(status: string | undefined): string | undefined {
    if (status === undefined) return 'N/A';
    return status[0] + status.substring(1).toLowerCase().replaceAll('_', ' ');
  }

  static findStatusIndex(statusText: string): number | undefined {
    return ShipmentStatusHandler.shipmentStatuses.indexOf(statusText);
  }

  static findNextStatusCode(currentStatus: string): number | undefined {
    const nextStatusIndex = this.findNextStatusIndex(currentStatus);
    if (nextStatusIndex === undefined) return undefined;
    return nextStatusIndex + 1;
  }

  static findNextShipmentStatus(currentStatus: string): string | undefined {
    const nextIndex = ShipmentStatusHandler.findNextStatusIndex(currentStatus);
    if (nextIndex === undefined) return undefined;
    return ShipmentStatusHandler.shipmentStatuses[nextIndex];
  }

  static findNextStatusIndex(currentStatus: string): number | undefined {
    const statuses = ShipmentStatusHandler.shipmentStatuses;
    const statusIndex = statuses.indexOf(currentStatus);
    if (statusIndex >= statuses.length - 3) return undefined;
    return statusIndex + 1;
  }
}
