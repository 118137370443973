import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from 'src/app-redux/rtkq.interceptor';
import { transformResponse } from 'src/app-redux/rtkq.util';
import { apiResources } from 'src/common/Constants';
import { environment } from 'src/environments/environment';
import { Driver, ICreateDriverArgs } from 'src/management/Driver';
import { driversTag } from 'src/app-redux/rtkq.tags';

export const driversApi = createApi({
  reducerPath: 'driversApi',
  tagTypes: [driversTag],
  baseQuery: baseQueryWithAuth,
  endpoints: (builder) => ({
    getDrivers: builder.query<Driver[], { page: number; searchParam: { [id: string]: string } }>({
      providesTags: [driversTag],
      query: (args) => {
        return {
          url: apiResources.driver,
          params: { page: args.page, ...args.searchParam },
          method: 'GET',
        };
      },
      transformResponse: transformResponse,
    }),
    findDriverByUsername: builder.query<Driver, string>({
      providesTags: [driversTag],
      query: (username) => `${apiResources.driver}/${username}`,
      transformResponse: transformResponse,
    }),
    createDriver: builder.mutation<Driver, ICreateDriverArgs>({
      query: (driver) => {
        return {
          url: apiResources.driver,
          method: 'POST',
          body: driver,
        };
      },
      invalidatesTags: (_result, error) => (error ? [] : [driversTag]),
    }),
    updateDriver: builder.mutation<Driver, Driver>({
      query: (driver) => {
        return {
          url: apiResources.driver,
          method: 'PUT',
          body: driver,
        };
      },
      transformResponse: transformResponse,
      invalidatesTags: (_result, error) => (error ? [] : [driversTag]),
    }),
    deleteDriver: builder.mutation<string, number>({
      query: (driverId) => ({
        url: `${apiResources.driver}/${driverId}`,
        method: 'DELETE',
      }),
      transformResponse: transformResponse,
      invalidatesTags: (_result, error) => (error ? [] : [driversTag]),
    }),
    /**
     * This API is used externally without authentication only in the delete driver account flow. DON"T USE IN NORMAL DELETE DRIVER FLOW.
     */
    deleteDriverAccount: builder.mutation<void, string>({
      query: (phoneNumber) => ({
        url: `${apiResources.driver}/delete-account/${phoneNumber}`,
        method: 'DELETE',
        body: {
          clientSecret: environment.VITE_CLIENT_SECRET_CSR,
        },
      }),
    }),
  }),
});

export const {
  useCreateDriverMutation,
  useGetDriversQuery,
  useLazyGetDriversQuery,
  useDeleteDriverAccountMutation,
  useLazyFindDriverByUsernameQuery,
  useDeleteDriverMutation,
  useUpdateDriverMutation,
} = driversApi;
