import { Api } from './Api';
import { apiResources } from './Constants';
import { EntityType, NotificationStatus, NotificationType } from './types/notification.enum';

export interface PushNotification {
  userName: string;
  ttl: Date;
  creationDate: Date;
  title: string;
  content: string;
  status: NotificationStatus;
  entityId: number;
  entityType: EntityType;
  notificationType: NotificationType;
}

export type UpdateNotificationRequestDto = {
  recipientType: 'user';
  entityType: EntityType;
  entityIds: number[];
  messageType: NotificationType;
};

class NotificationApi {
  private readonly api = new Api();

  async fetchNotifications(): Promise<PushNotification[]> {
    return await this.api.getResource(`${apiResources.notifications}`);
  }

  async markAllNotificationsAsRead(): Promise<void> {
    return await this.api.createResource(`${apiResources.notificationsMarkAllRead}`, {});
  }
}

export async function fetchNotifications(): Promise<PushNotification[]> {
  const shipmentApi = new NotificationApi();
  return await shipmentApi.fetchNotifications();
}

export async function markAllNotificationsAsRead(): Promise<void> {
  const shipmentApi = new NotificationApi();
  return await shipmentApi.markAllNotificationsAsRead();
}
