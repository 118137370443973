import { configureStore, type Action, type ThunkAction } from '@reduxjs/toolkit';
import { otpsApi } from './auth/otpsAPI';
import { bidsApi } from './bids/bidsAPI';
import { bookingsApi } from './bookings/bookingsAPI';
import { lookupsApi } from './lookup/lookupAPI';
import { demandAddasApi } from './management/demand-addas/demandAddaAPIs';
import { driversApi } from './management/drivers/driversAPI';
import { fleetsApi } from './management/fleets/fleetsAPI';
import { fuelPumpsApi } from './management/fuel-pumps/fuelPumpsAPI';
import { locationsApi } from './management/locations/locationsAPI';
import { documentsApi } from './management/partners/documentsAPI';
import { partnersApi } from './management/partners/partnersAPI';
import { partnerVerificationsApi } from './management/partners/verificationsAPI';
import { rolesApi } from './management/roles/rolesAPI';
import { usersApi } from './management/usersAPI.ts/usersAPI';
import { vehiclesApi } from './management/vehicles/vehiclesAPI';
import { notificationsSlice } from './notifications/notificationsSlice';
import { notificationsApi } from './notifications/services/notificationsAPI';
import { configApi } from './settings/services/configAPI';
import { settingsSlice } from './settings/settingsSlice';
import { shipmentsApi } from './shipments/shipmentsAPI';
import { templatesApi } from './shipments/templatesAPI';
import { thirdPartyApi } from './thirdParty/thirdPartyAPI';
import { verificationsApi } from './verifications/verificationsAPI';

/**
 * @link https://redux-toolkit.js.org/tutorials/typescript Redux-Toolkit Typescript Docs
 */
export const store = configureStore({
  reducer: {
    // counter: counterReducer,
    settings: settingsSlice.reducer,
    // auth: authSlice.reducer,
    notifications: notificationsSlice.reducer,
    [notificationsApi.reducerPath]: notificationsApi.reducer,
    [locationsApi.reducerPath]: locationsApi.reducer,
    [configApi.reducerPath]: configApi.reducer,
    [documentsApi.reducerPath]: documentsApi.reducer,
    [templatesApi.reducerPath]: templatesApi.reducer,
    [shipmentsApi.reducerPath]: shipmentsApi.reducer,
    [bookingsApi.reducerPath]: bookingsApi.reducer,
    [bidsApi.reducerPath]: bidsApi.reducer,
    [partnerVerificationsApi.reducerPath]: partnerVerificationsApi.reducer,
    [thirdPartyApi.reducerPath]: thirdPartyApi.reducer,
    [lookupsApi.reducerPath]: lookupsApi.reducer,
    [partnersApi.reducerPath]: partnersApi.reducer,
    [driversApi.reducerPath]: driversApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [fleetsApi.reducerPath]: fleetsApi.reducer,
    [otpsApi.reducerPath]: otpsApi.reducer,
    [vehiclesApi.reducerPath]: vehiclesApi.reducer,
    [demandAddasApi.reducerPath]: demandAddasApi.reducer,
    [verificationsApi.reducerPath]: verificationsApi.reducer,
    [fuelPumpsApi.reducerPath]: fuelPumpsApi.reducer,
    [rolesApi.reducerPath]: rolesApi.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      notificationsApi.middleware,
      locationsApi.middleware,
      configApi.middleware,
      documentsApi.middleware,
      templatesApi.middleware,
      shipmentsApi.middleware,
      bookingsApi.middleware,
      bidsApi.middleware,
      partnerVerificationsApi.middleware,
      thirdPartyApi.middleware,
      lookupsApi.middleware,
      partnersApi.middleware,
      driversApi.middleware,
      usersApi.middleware,
      fleetsApi.middleware,
      otpsApi.middleware,
      vehiclesApi.middleware,
      demandAddasApi.middleware,
      verificationsApi.middleware,
      fuelPumpsApi.middleware,
      rolesApi.middleware,
    ),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
export type TActionMeta<T = void> = {
  requestId: string;
  requestStatus: 'pending' | 'fulfilled' | 'rejected';
  arg?: T;
  aborted?: boolean;
  condition?: boolean;
  rejectedWithValue?: boolean;
};
