import { store } from 'src/app-redux/store';
import { thirdPartyApi } from 'src/app-redux/thirdParty/thirdPartyAPI';
import LocalCache from 'src/common/LocalCache';
import { loadPreferencesIntoLocalStorage } from 'src/configuration/Configuration';

async function versionCheck() {
  try {
    const res = await fetch('/build.json'); // Created using GitLab Pipeline
    const { commit } = await res.json();
    const newHash = commit.split('commit/')[1];
    const oldHash = LocalCache.fetchHash();

    if (!newHash || newHash === oldHash) return;
    // new version loaded
    LocalCache.clearCachedData();
    LocalCache.saveHash(newHash);
  } catch (error: any) {
    console.error('versionCheck error:', error.message);
  }
}

function prefetchLocation() {
  /* Prefetch IP Based Location. Similar to `thirdPartyApi.usePrefetch('getIPLocation')();` */
  if (!LocalCache.fetchIPLocation()) {
    store.dispatch(thirdPartyApi.endpoints.getIPLocation.initiate());
  }
}

(async function init() {
  await loadPreferencesIntoLocalStorage();
  await versionCheck();
  prefetchLocation();
})();
