import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from 'src/app-redux/rtkq.interceptor';
import { partnerVerificationsTag } from 'src/app-redux/rtkq.tags';
import { transformResponse } from 'src/app-redux/rtkq.util';
import { apiResources } from 'src/common/Constants';
import type { WithRequired } from 'src/common/types/UtilityTypes.type';
import type { PartnerDocument, UploadDocumentArgs } from 'src/management/PartnerDocuments';

const documentsTag = 'documents';

export const documentsApi = createApi({
  reducerPath: 'documentsApi',
  tagTypes: [documentsTag, partnerVerificationsTag],
  baseQuery: baseQueryWithAuth,
  endpoints: (builder) => ({
    fetchAllDocuments: builder.query<PartnerDocument[], number>({
      query: (userId) => `${apiResources.userDocuments}/${userId}/all`,
      providesTags: [documentsTag, partnerVerificationsTag],
      transformResponse,
    }),
    uploadDocument: builder.mutation<string, WithRequired<UploadDocumentArgs, 'documentTitle' | 'file'>>({
      query: (body) => {
        const formData = new FormData();
        for (const key in body) {
          if (Object.prototype.hasOwnProperty.call(body, key)) {
            const element = body[key as keyof typeof body];
            formData.append(key, element as any);
          }
        }
        return {
          url: `${apiResources.userDocuments}/upload`,
          method: 'POST',
          body: formData,
        };
      },
      invalidatesTags: (_result, error) => (error ? [] : [documentsTag]),
      transformResponse,
    }),
  }),
});

export const { useFetchAllDocumentsQuery, useUploadDocumentMutation } = documentsApi;
