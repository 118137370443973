import { GOODS_TYPE_ENUM } from './Lookup';
import {
  DropOffDetails,
  EmptyNewShipment,
  LocationActionType,
  NewShipmentLoad,
  PickUpDetails,
  ShipmentLocations,
} from './Shipment';

export default class ShipmentLocationHelper {
  static filterPickupLocations(shipmentLocations: ShipmentLocations): PickUpDetails[] {
    return shipmentLocations?.filter(
      (l) => l.locationActionType === LocationActionType.PICK_UP,
    ) as any as PickUpDetails[];
  }

  static filterDropOffLocations(shipmentLocations: ShipmentLocations): DropOffDetails[] {
    return shipmentLocations?.filter(
      (l) => l.locationActionType === LocationActionType.DROP_OFF,
    ) as any as DropOffDetails[];
  }

  static getAllLoads(
    shipmentLocations: EmptyNewShipment['dropOffLocations'] | EmptyNewShipment['pickupLocations'] | ShipmentLocations,
  ) {
    const loads = shipmentLocations?.map((d) => d?.loads).flat();
    return loads;
  }

  static getAllWeights(dropOffDetails: EmptyNewShipment['dropOffLocations'] | DropOffDetails[]) {
    const loads = dropOffDetails?.map((d) => d?.loads).flat();
    const weights = loads?.map((l) => l?.weight);
    return weights;
  }

  static getFormattedWeights(dropOffDetails: EmptyNewShipment['dropOffLocations'] | DropOffDetails[], unit: string) {
    const weights = ShipmentLocationHelper.getAllWeights(dropOffDetails);
    return weights?.map((w) => `${w} ${unit}`).join(', ');
  }

  static getAllGoods(dropOffDetails: EmptyNewShipment['dropOffLocations'] | DropOffDetails[]) {
    const loads = dropOffDetails?.map((d) => d?.loads).flat();
    const goods = loads?.map((l) => l?.goodsType);
    return goods;
  }

  static getFormattedGoods(dropOffDetails: EmptyNewShipment['dropOffLocations'] | DropOffDetails[]) {
    const goods = ShipmentLocationHelper.getAllGoods(dropOffDetails);
    return goods?.join(', ');
  }

  static getFormattedCityNames(
    shipmentLocation: ShipmentLocations,
    options: { truncate: boolean } = { truncate: false },
  ) {
    const cityNames = shipmentLocation?.map((l) => l?.cityName);
    if (options.truncate) {
      return cityNames?.slice(0, 2).join(', ') + (cityNames?.length > 2 ? ` + ${cityNames.length - 2}` : '');
    }
    return cityNames?.join(', ');
  }

  static areLoadsHSCode(shipmentLocation: ShipmentLocations) {
    const loads = shipmentLocation.map((d) => d?.loads).flat();
    if (!loads || loads.length === 0) return false;
    return loads.every((l) => (l?.goodsCodeType ? l.goodsCodeType === GOODS_TYPE_ENUM.HS_CODE : false));
  }

  static attachLoadsTo(to: EmptyNewShipment['dropOffLocations'] | EmptyNewShipment['pickupLocations']) {
    return {
      from: (from: EmptyNewShipment['dropOffLocations'] | EmptyNewShipment['pickupLocations']) => {
        return to?.map((t) => ({
          ...t,
          loads: this.getAllLoads(from) as any as NewShipmentLoad[],
        }));
      },
    };
  }

  static remapCustomLocation(
    shipmentLocations: EmptyNewShipment['dropOffLocations'] | EmptyNewShipment['pickupLocations'],
    options: { delete: boolean } = { delete: true },
  ) {
    return shipmentLocations?.map((s) => {
      if (s?.customLocation) {
        const out = {
          ...s,
          address: s.customLocation.address1 + ', ' + s.customLocation.address2 + ', ' + s.customLocation.address3,
          cityName: s.customLocation.cityName,
          latitude: s.customLocation.lat,
          longitude: s.customLocation.lng,
          cityId: s.customLocation.cityId,
          zipCode: s.customLocation.zip,
        };
        if (options.delete) {
          delete out.customLocation;
        }
        return out;
      }
      return s;
    });
  }
}
