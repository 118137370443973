export interface LookupObject {
  id: number;
  name: string;
}

export interface City extends LookupObject {
  threeCharCode: string;
  countryId: number;
  latitude: number;
  longitude: number;
}

export enum GOODS_TYPE_ENUM {
  DEFAULT = 1,
  HS_CODE,
}

export interface GoodsType extends LookupObject {
  typeId: GOODS_TYPE_ENUM;
}

export const othersGoodsTypeId = 35;

export interface VehicleVariant extends LookupObject {
  vehicleTypeId: number;
}
