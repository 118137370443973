import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from 'src/app-redux/rtkq.interceptor';
import { apiResources } from 'src/common/Constants';
import { IDriverVerificationArgs } from 'src/management/Driver';
import { IVehicleVerificationArgs } from 'src/management/Vehicle';
import { driversApi } from '../management/drivers/driversAPI';
import { vehiclesApi } from '../management/vehicles/vehiclesAPI';
import { driversTag, vehiclesTag } from '../rtkq.tags';

export const verificationsApi = createApi({
  reducerPath: 'verificationsApi',
  baseQuery: baseQueryWithAuth,
  endpoints: (builder) => ({
    driverVerification: builder.mutation<string, IDriverVerificationArgs>({
      query: (args) => ({
        url: `${apiResources.verfication}/update-driver`,
        method: 'POST',
        body: args,
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        await queryFulfilled;
        dispatch(driversApi.util.invalidateTags([driversTag]));
      },
    }),
    vehicleVerification: builder.mutation<string, IVehicleVerificationArgs>({
      query: (args) => ({
        url: `${apiResources.verfication}/update-vehicle`,
        method: 'POST',
        body: args,
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        await queryFulfilled;
        dispatch(vehiclesApi.util.invalidateTags([vehiclesTag]));
      },
    }),
  }),
});

export const { useDriverVerificationMutation, useVehicleVerificationMutation } = verificationsApi;
