/**
 * A method that returns nested value dynamically.
 * @param object Target Object
 * @param path Search Patch e.g `key.nested.path`
 * @param defaultValue default not found value
 * @returns any nested path value
 */
export function resolveDynamicPath<T = undefined>(object: any, path: string, defaultValue?: T): T {
  return path.split('.').reduce((o, p) => (o ? o[p] : defaultValue), object);
}

export function containsValidData(obj: Record<string, any>, filter: any[] = [undefined, null, '']): boolean {
  return !Object.keys(obj).some((key) => filter.includes(obj[key]));
}

/** Removes 1st level attributes having values of `null`, `undefined` etc */
export function filterObject(obj: Record<string, any>, filter: any[] = [undefined, null]): Record<string, any> {
  return Object.entries(obj).filter(([_, v]) => !filter.includes(v));
}
