import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import LocalCache from 'src/common/LocalCache';

export const baseQueryWithAuth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  const result = await fetchBaseQuery({
    prepareHeaders: (headers) => {
      const token = LocalCache.fetchToken();
      headers.set('Authorization', `Bearer ${token}`);
      return headers;
    },
  })(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    if (!window.location.pathname.includes('/auth'))
      window?.location.replace(`/auth/login?redirect=${window.location.pathname}`);
  }
  return result;
};
