import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from 'src/app-redux/rtkq.interceptor';
import { transformResponse } from 'src/app-redux/rtkq.util';
import { apiResources } from 'src/common/Constants';
import { CreateFuelPumpsArgs, FetchFuelPumpsArgs, FuelPump } from 'src/management/FuelPump';

const fuelPumpsTag = 'fuelPumps';

export const fuelPumpsApi = createApi({
  reducerPath: 'fuelPumpsApi',
  tagTypes: [fuelPumpsTag],
  baseQuery: baseQueryWithAuth,
  endpoints: (builder) => ({
    fetchAllFuelPumps: builder.query<FuelPump[], FetchFuelPumpsArgs>({
      query: (args) => ({
        url: `${apiResources.fuelPump}/all`,
        method: 'GET',
        params: {
          page: args.page,
          [args.searchBy]: args.fuelPumpName,
        },
      }),
      transformResponse,
      providesTags: [fuelPumpsTag],
    }),
    createFuelPump: builder.mutation<void, CreateFuelPumpsArgs>({
      query: (args) => ({
        url: `${apiResources.fuelPump}/create`,
        method: 'POST',
        body: args,
      }),
      invalidatesTags: (_result, error) => (error ? [] : [fuelPumpsTag]),
    }),
  }),
});

export const { useFetchAllFuelPumpsQuery, useLazyFetchAllFuelPumpsQuery, useCreateFuelPumpMutation } = fuelPumpsApi;
