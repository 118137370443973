import { environment } from '../environments/environment';

const endpoint = environment.VITE_API_ENDPOINT;
// "proxy": "http://54.254.45.123",

export const apiResources = {
  endpoint,
  bid: endpoint + '/bid',
  booking: endpoint + '/booking',
  bank: endpoint + '/bank/v1',
  bankV0: endpoint + '/bank',
  allBanks: endpoint + '/bank/v1/all',
  tracking: endpoint + '/booking',
  bookingTrackingInfo: endpoint + '/tracking/bookingLocation',
  city: endpoint + '/city/country/1?page=0',
  allCountries: endpoint + '/country/all',
  company: endpoint + '/company',
  driver: endpoint + '/driver',
  driverVehicleType: endpoint + '/driver-vehicle-type',
  fleet: endpoint + '/fleet',
  goodType: endpoint + '/goods-type?page=0',
  loginInternal: endpoint + '/auth/user/login',
  loginShipper: endpoint + '/auth/user/shipper/login',
  signup: endpoint + '/auth/user/user-account',
  refreshToken: endpoint + '/auth/user/refresh',
  shipment: endpoint + '/shipment',
  shipmentTracking: endpoint + '/shipment',
  notifications: endpoint + '/notification/user/all',
  notificationsMarkAllRead: endpoint + '/notification/markAllRead/user',
  notificationsRead: endpoint + '/notification/updateNotificationStatus',
  user: endpoint + '/user',
  internalUsers: endpoint + '/user/internal',
  forgetPassword: endpoint + '/auth/user/forgot-password',
  resetPasswordForUser: endpoint + '/auth/user/reset-password',
  resetPasswordForDriver: endpoint + '/auth/driver/reset-password',
  changePasswordForUser: endpoint + '/user/{userId}/changePassword',
  changePasswordForDriver: endpoint + '/driver/{userId}/changePassword',
  partners: endpoint + '/user/partner',
  vehicle: endpoint + '/vehicle',
  userDocuments: endpoint + '/userDocument',
  vehicleType: endpoint + '/vehicle-type?page=0',
  vehicleVariant: endpoint + '/vehicle-type-variant/vehicle-type',
  transactionHistory: endpoint + '/wallet/accountHolder/{accountHolderId}/type/{accountHolderTypeId}/days/{days}',
  config: endpoint + '/config',
  shipperConfig: endpoint + '/config/shipper',
  bookingDocUpload: endpoint + '/bookingDocument/upload',
  bookingDocuments: endpoint + '/bookingDocument',
  shipmentDocUpload: endpoint + '/shipment-document/upload',
  shipmentDocuments: endpoint + '/shipment-document',
  partnerVerification: endpoint + '/verification',
  tyreDiscount: endpoint + '/tyres',
  csrDashboardStats: endpoint + '/report/v1/csr/counts',
  shipperDashboardStats: endpoint + '/report/v1/shipper/counts',
  csrBidStats: endpoint + '/report/v1/csr/bids',
  digitizeV1: endpoint + '/digitize/v1',
  transactions: endpoint + '/bank/v1/payment/all',
  refusals: endpoint + '/reason/refusal',
  allPayments: endpoint + '/booking/v3/pricing',
  invoice: endpoint + '/invoice',
  invoiceDoc: endpoint + '/invoice-document',
  verfication: endpoint + '/verification/v1',
  updateUserVerfication: endpoint + '/verification/v1/update-user',
  settlePayment: endpoint + '/bank/v1/payment/batch',
  fuelPump: endpoint + '/fuelPump',
  getEnums: endpoint + '/billing/v1/invoice/configuration-enums',
  searchCompany: endpoint + '/company/v1/configurations',
  getCompanyDetails: endpoint + '/billing/v1/invoice/configuration',
  createConfig: endpoint + '/billing/v1/invoice/create-update-configuration',
  preferences: endpoint + '/preferences',
  shipmentTemplate: endpoint + '/shipment-template',
  roleV2: endpoint + '/role/v2',
  permission: endpoint + '/permission',
  billTPrint: endpoint + '/document/bill-t/print',
  billTPreview: endpoint + '/document/bill-t/preview',
  generateForgetPasswordOtp: endpoint + '/otp/forgot-password/generate',
  validateForgetPasswordOtp: endpoint + '/otp/forgot-password/validate',
  location: endpoint + '/location',
  partnerVerificationConfig: endpoint + '/verification-config',
  partnerVerificationData: endpoint + '/partner_verification_data',
  partnerVerificationDataVerify: endpoint + '/verification/v1/update-user-data',
  partnerVerificationUpdateDocument: endpoint + '/verification/v1/update-user-document',
  generatePartnerOtp: endpoint + '/otp/generate/user',
  validatePartnerOtp: endpoint + '/otp/validate/user',
  generateDriverOtp: endpoint + '/otp/generate/driver',
  validateDriverOtp: endpoint + '/otp/validate/driver',
  demandAdda: endpoint + '/demand-adda',
} as const;

export const vehicleImages: { [key: string]: string } = {
  Containerized: 'icons/vehicles/container.svg',
  Flatbed: 'icons/vehicles/flatbed.svg',
  Halfbody: 'icons/vehicles/halfbody.svg',
  Mazda: 'icons/vehicles/mazda.svg',
  Shehzore: 'icons/vehicles/shahzore.svg',
  Suzuki: 'icons/vehicles/pickup.svg',
  Reefer: 'icons/vehicles/reefer.svg',
  Default: 'icons/vehicles/container.svg',
};

export const dollarSign = '/icons/shipment-types/contract-amount.svg';

export const bookingStatusesColorMapping = [
  'BlueViolet',
  'Brown',
  'Chocolate',
  'OrangeRed',
  'DarkGoldenRod',
  'DarkOrange',
  'DeepPink',
  'GreenYellow',
  'Indigo',
  'Olive',
  'SlateGray',
  'LawnGreen',
  'DarkMagenta',
];

export const shipmentTypesValidationDataset = [
  {
    shipmentCategoryTypeId: 2,
    shipmentType: 'CONTRACTUAL',
    bidCategoryType: 'PER_TONNE',
    bidCategoryTypeId: 2,
    minAmount: 1000,
    maxAmount: 8000,
  },
  {
    shipmentCategoryTypeId: 2,
    shipmentType: 'CONTRACTUAL',
    bidCategoryType: 'PER_VEHICLE',
    bidCategoryTypeId: 1,
    vehcileType: 'Containerized',
    vehicleTypeId: 1,
    minAmount: 30000,
    maxAmount: 450000,
  },
  {
    shipmentCategoryTypeId: 2,
    shipmentType: 'CONTRACTUAL',
    bidCategoryType: 'PER_VEHICLE',
    bidCategoryTypeId: 1,
    vehcileType: 'Flatbed',
    vehicleTypeId: 2,
    minAmount: 30000,
    maxAmount: 450000,
  },
  {
    shipmentCategoryTypeId: 2,
    shipmentType: 'CONTRACTUAL',
    bidCategoryType: 'PER_VEHICLE',
    bidCategoryTypeId: 1,
    vehcileType: 'Halfbody',
    vehicleTypeId: 3,
    minAmount: 30000,
    maxAmount: 450000,
  },
  {
    shipmentCategoryTypeId: 2,
    shipmentType: 'CONTRACTUAL',
    bidCategoryType: 'PER_VEHICLE',
    bidCategoryTypeId: 1,
    vehcileType: 'Mazda',
    vehicleTypeId: 4,
    minAmount: 30000,
    maxAmount: 450000,
  },
  {
    shipmentCategoryTypeId: 2,
    shipmentType: 'CONTRACTUAL',
    bidCategoryType: 'PER_VEHICLE',
    bidCategoryTypeId: 1,
    vehcileType: 'Suzuki',
    vehicleTypeId: 5,
    minAmount: 1000,
    maxAmount: 8000,
  },
  {
    shipmentCategoryTypeId: 2,
    shipmentType: 'CONTRACTUAL',
    bidCategoryType: 'PER_VEHICLE',
    bidCategoryTypeId: 1,
    vehcileType: 'Shehzore',
    vehicleTypeId: 6,
    minAmount: 30000,
    maxAmount: 450000,
  },
  {
    shipmentCategoryTypeId: 2,
    shipmentType: 'CONTRACTUAL',
    bidCategoryType: 'PER_VEHICLE',
    bidCategoryTypeId: 1,
    vehcileType: 'Reefer',
    vehicleTypeId: 7,
    minAmount: 30000,
    maxAmount: 450000,
  },
];

export const vehicleVariantsDataSets = [
  {
    vehicleVariantTypeId: [24, 25, 26],
    minWeight: 0,
    maxWeight: 2,
  },
  {
    vehicleVariantTypeId: [27, 28, 29],
    minWeight: 1,
    maxWeight: 20,
  },
  {
    vehicleVariantTypeId: [15, 16, 17, 19, 20],
    minWeight: 1,
    maxWeight: 30,
  },
  {
    vehicleVariantTypeId: [18, 21, 22, 23, 33, 34],
    minWeight: 5,
    maxWeight: 40,
  },
  {
    vehicleVariantTypeId: [1, 5, 10, 31],
    minWeight: 10,
    maxWeight: 70,
  },
  {
    vehicleVariantTypeId: [6, 11, 36, 37],
    minWeight: 10,
    maxWeight: 80,
  },
  {
    vehicleVariantTypeId: [35],
    minWeight: 10,
    maxWeight: 120,
  },
  {
    vehicleVariantTypeId: [2, 3, 4, 7, 8, 9, 12, 13, 14, 30, 32],
    minWeight: 30,
    maxWeight: 120,
  },
];

export const nullLocation = { lat: 1.0, lng: 1.0 };
export const DEFAULT_VALUE = 'N/A';
export const IS_DEMO_MODE_ACTIVATED = false;
export const ShipmentOverviewPollInterval = 2 * 60 * 1000; // 2 minutes
export const BookingOverviewPollInterval = 2 * 60 * 1000; // 2 minutes
export const DRAWER_WIDTH = 240;
export const PAKISTAN_COUNTRY_ID = 1;
export const PIN_LENGTH = 4;
export const SHIPPER_RATE_TERMINOLOGY_OPTIONS = ['Shipper Rate', 'Target Rate'];
export const VEHICLE_TYPE_TERMINOLOGY_OPTIONS = ['Vehicle Type', 'Cargo Type'];
