import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from 'src/app-redux/rtkq.interceptor';
import { partnersTag } from 'src/app-redux/rtkq.tags';
import { transformResponse } from 'src/app-redux/rtkq.util';
import { apiResources } from 'src/common/Constants';
import { environment } from 'src/environments/environment';
import { ICreatePartnerArgs } from 'src/management/Partner/Partner';
import { User } from 'src/management/User';
import { partnersApi } from '../partners/partnersAPI';

const usersTag = 'users';

export const usersApi = createApi({
  reducerPath: 'usersApi',
  tagTypes: [usersTag],
  baseQuery: baseQueryWithAuth,
  endpoints: (builder) => ({
    /**
     * This API is used externally without authentication only in the delete partner account flow. DON"T USE IN NORMAL DELETE PARTNER FLOW.
     */
    deletePartnerAccount: builder.mutation<void, string>({
      query: (phoneNumber) => ({
        url: `${apiResources.user}/partner/delete-account/${phoneNumber}`,
        method: 'DELETE',
        body: {
          clientSecret: environment.VITE_CLIENT_SECRET_CSR,
        },
      }),
    }),
    createUser: builder.mutation<User, ICreatePartnerArgs>({
      query: (args) => {
        return {
          url: apiResources.user,
          method: 'POST',
          body: args,
        };
      },
      transformResponse: transformResponse,
      invalidatesTags: (_result, error) => (error ? [] : [usersTag]),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        await queryFulfilled;
        dispatch(partnersApi.util.invalidateTags([partnersTag]));
      },
    }),
  }),
});

export const { useCreateUserMutation, useDeletePartnerAccountMutation } = usersApi;
