import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from 'src/app-redux/rtkq.interceptor';
import { transformResponse } from 'src/app-redux/rtkq.util';
import { apiResources } from 'src/common/Constants';
import { FetchRolesArgs, NewRoleArgs, Role, UpdateRoleArgs } from 'src/rbac/RolesAndPermissions';

const rolesTag = 'roles';

export const rolesApi = createApi({
  reducerPath: 'rolesApi',
  tagTypes: [rolesTag],
  baseQuery: baseQueryWithAuth,
  endpoints: (builder) => ({
    fetchRoles: builder.query<Role[], FetchRolesArgs>({
      providesTags: [rolesTag],
      query: (args) => {
        return {
          url: `${apiResources.roleV2}/all`,
          method: 'GET',
          params: args.all ? undefined : { page: args.page, pageSize: args.pageSize },
        };
      },
      transformResponse: transformResponse,
    }),
    createRole: builder.mutation<void, NewRoleArgs>({
      query: (args) => {
        return {
          url: `${apiResources.roleV2}`,
          method: 'POST',
          body: args,
        };
      },
      invalidatesTags: (_result, error) => (error ? [] : [rolesTag]),
    }),
    updateRole: builder.mutation<void, UpdateRoleArgs>({
      query: (args) => {
        return {
          url: `${apiResources.roleV2}/${args.roleId}`,
          method: 'PUT',
          body: {
            name: args.name,
            permissions: args.permissions,
          },
        };
      },
      invalidatesTags: (_result, error) => (error ? [] : [rolesTag]),
    }),
    deleteRole: builder.mutation<void, number>({
      query: (roleId) => {
        return {
          url: `${apiResources.roleV2}/${roleId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: (_result, error) => (error ? [] : [rolesTag]),
    }),
  }),
});

export const { useFetchRolesQuery, useCreateRoleMutation, useUpdateRoleMutation, useDeleteRoleMutation } = rolesApi;
