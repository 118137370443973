import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from 'src/app-redux/rtkq.interceptor';
import { vehiclesTag } from 'src/app-redux/rtkq.tags';
import { transformResponse } from 'src/app-redux/rtkq.util';
import { apiResources } from 'src/common/Constants';
import { Vehicle } from 'src/management/Vehicle';

export const vehiclesApi = createApi({
  reducerPath: 'vehiclesApi',
  tagTypes: [vehiclesTag],
  baseQuery: baseQueryWithAuth,
  endpoints: (builder) => ({
    fetchVehicles: builder.query<Vehicle[], number>({
      query: (page) => `${apiResources.vehicle}?page=${page}`,
      providesTags: [vehiclesTag],
      transformResponse: transformResponse,
    }),
    searchVehiclesByRegistrationNumber: builder.query<Vehicle[], string>({
      query: (registrationNumber) => `${apiResources.vehicle}/search?registrationNumber=${registrationNumber}`,
      providesTags: [vehiclesTag],
      transformResponse: transformResponse,
    }),
    findVehicleByRegistrationNumber: builder.query<Vehicle, string>({
      query: (registrationNumber) => `${apiResources.vehicle}/registration/${registrationNumber}`,
      transformResponse: transformResponse,
    }),
    createVehicle: builder.mutation<void, Vehicle>({
      query: (vehicle) => ({
        url: apiResources.vehicle,
        method: 'POST',
        body: vehicle,
      }),
      transformResponse: transformResponse,
      invalidatesTags: (_result, error) => (error ? [] : [vehiclesTag]),
    }),
    updateVehicle: builder.mutation<void, Vehicle>({
      query: (vehicle) => ({
        url: apiResources.vehicle,
        method: 'PUT',
        body: vehicle,
      }),
      transformResponse: transformResponse,
      invalidatesTags: (_result, error) => (error ? [] : [vehiclesTag]),
    }),
    assignDriverToVehicle: builder.mutation<void, { driverId: number; vehicleId: number }>({
      query: ({ driverId, vehicleId }) => ({
        url: `${apiResources.vehicle}/${vehicleId}/driver/${driverId}/assign`,
        method: 'POST',
      }),
      transformResponse: transformResponse,
    }),
  }),
});

export const {
  useFetchVehiclesQuery,
  useSearchVehiclesByRegistrationNumberQuery,
  useLazyFindVehicleByRegistrationNumberQuery,
  useLazySearchVehiclesByRegistrationNumberQuery,
  useCreateVehicleMutation,
  useUpdateVehicleMutation,
  useAssignDriverToVehicleMutation,
} = vehiclesApi;
