import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from 'src/app-redux/rtkq.interceptor';
import { partnerVerificationsTag } from 'src/app-redux/rtkq.tags';
import { transformResponse } from 'src/app-redux/rtkq.util';
import { apiResources } from 'src/common/Constants';
import { PartnerVerificationConfig } from 'src/configuration/PartnerVerificationConfig/PartnerVerificationConfig';
import {
  PartnerVerificationDataArgs,
  UpdateDocumentArgs,
  UpdatePartnerVerificationArgs,
  UploadPartnerVerificationDataArgs,
  VerifyPartnerVerificationDataArgs,
} from 'src/management/PartnerDocuments';
import { documentsApi } from './documentsAPI';

const verificationConfigTag = 'verificationConfig';
const verificationDataTag = 'verificationData';

export const partnerVerificationsApi = createApi({
  reducerPath: 'partnerVerificationsApi',
  tagTypes: [verificationConfigTag, verificationDataTag],
  baseQuery: baseQueryWithAuth,
  endpoints: (builder) => ({
    getVerificationConfig: builder.query<Array<PartnerVerificationConfig>, void>({
      providesTags: [verificationConfigTag],
      query: () => `${apiResources.partnerVerificationConfig}`,
      transformResponse,
    }),
    submitVerificationConfig: builder.mutation<void, { body: Array<PartnerVerificationConfig> }>({
      query: ({ body }) => ({
        url: `${apiResources.partnerVerificationConfig}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (_result, error) => (error ? [] : [verificationConfigTag]),
    }),
    updatePartnerVerification: builder.mutation<string, UpdatePartnerVerificationArgs>({
      query: (body) => ({
        url: apiResources.updateUserVerfication,
        method: 'POST',
        body,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
    updateVerificationDocument: builder.mutation<string, UpdateDocumentArgs>({
      query: (body) => ({
        url: apiResources.partnerVerificationUpdateDocument,
        method: 'POST',
        body,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        await queryFulfilled;
        dispatch(documentsApi.util.invalidateTags([partnerVerificationsTag]));
      },
    }),
    getPartnerVerificationData: builder.query<Array<PartnerVerificationDataArgs>, number>({
      providesTags: [verificationDataTag],
      query: (partnerId: number) => `${apiResources.partnerVerificationData}/${partnerId}`,
      transformResponse,
    }),
    uploadPartnerVerificationData: builder.mutation<string, UploadPartnerVerificationDataArgs>({
      query: (body) => ({
        url: apiResources.partnerVerificationData,
        method: 'POST',
        body,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
      invalidatesTags: (_result, error) => (error ? [] : [verificationDataTag]),
    }),
    verifyPartnerVerificationData: builder.mutation<string, VerifyPartnerVerificationDataArgs>({
      query: (body) => ({
        url: apiResources.partnerVerificationDataVerify,
        method: 'POST',
        body,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
      invalidatesTags: (_result, error) => (error ? [] : [verificationDataTag]),
    }),
  }),
});

export const {
  useGetVerificationConfigQuery,
  useSubmitVerificationConfigMutation,
  useUpdatePartnerVerificationMutation,
  useUpdateVerificationDocumentMutation,
  useGetPartnerVerificationDataQuery,
  useUploadPartnerVerificationDataMutation,
  useVerifyPartnerVerificationDataMutation,
} = partnerVerificationsApi;
