import { type Draft, type PayloadAction } from '@reduxjs/toolkit';
import { type TSettingsState } from '../settingsSlice';

/**
 * Don't use directly, instead use `openDrawerAction` from the corresponding slice/reduce file
 */
export const setOpenDrawer = (state: Draft<TSettingsState>, action: PayloadAction<boolean>) => {
  state.openMobileDrawer = action.payload;
};

export const toggleMobileDrawer = (state: Draft<TSettingsState>) => {
  state.openMobileDrawer = !state.openMobileDrawer;
};
