import { createSlice } from '@reduxjs/toolkit';
import { customThemeOptions } from 'src/theme/customTheme';
import { type RootState } from '../store';
import { setOpenDrawer, toggleMobileDrawer } from './actions/drawerActions';
import { setCustomTheme } from './actions/setThemeType';
import LocalCache from 'src/common/LocalCache';

export type TCustomTheme = { primary: string; secondary: string; mode: 'light' | 'dark' };
export type TSettingsState = {
  customTheme: TCustomTheme;
  openMobileDrawer: boolean;
};

const initialState: TSettingsState = {
  customTheme: {
    primary: (customThemeOptions.palette?.primary as any).main,
    secondary: (customThemeOptions.palette?.secondary as any).main,
    mode: LocalCache.getTheme() || 'light',
  },
  openMobileDrawer: false,
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    customThemeAction: setCustomTheme,
    openDrawerAction: setOpenDrawer,
    toggleMobileDrawerAction: toggleMobileDrawer,
  },
});

export const { customThemeAction, openDrawerAction, toggleMobileDrawerAction } = settingsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectCustomTheme = (state: RootState) => state.settings.customTheme;
export const selectMobileDrawer = (state: RootState) => state.settings.openMobileDrawer;
