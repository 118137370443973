import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  ForgetPasswordToken,
  GenerateForgetPasswordOtpArgs,
  ValidateForgetPasswordOtpArgs,
} from 'src/auth/forget-password/forget-password';
import { apiResources } from 'src/common/Constants';
import { environment } from 'src/environments/environment';
import { transformResponse } from '../rtkq.util';

export const otpsApi = createApi({
  reducerPath: 'otpsApi',
  baseQuery: fetchBaseQuery(),
  endpoints: (builder) => ({
    generatePartnerOtp: builder.mutation<void, string>({
      query: (phoneNumber) => ({
        url: `${apiResources.generatePartnerOtp}/${phoneNumber}`,
        method: 'POST',
        body: {
          clientSecret: environment.VITE_CLIENT_SECRET_CSR,
        },
      }),
    }),
    validatePartnerOtp: builder.query<void, { phoneNumber: string; otp: string }>({
      query: ({ phoneNumber, otp }) => `${apiResources.validatePartnerOtp}/${phoneNumber}/${otp}`,
    }),
    generateDriverOtp: builder.mutation<void, string>({
      query: (phoneNumber) => ({
        url: `${apiResources.generateDriverOtp}/${phoneNumber}`,
        method: 'POST',
        body: {
          clientSecret: environment.VITE_CLIENT_SECRET_CSR,
        },
      }),
    }),
    validateDriverOtp: builder.query<void, { phoneNumber: string; otp: string }>({
      query: ({ phoneNumber, otp }) => `${apiResources.validateDriverOtp}/${phoneNumber}/${otp}`,
    }),
    generateForgetPasswordOtp: builder.query<void, GenerateForgetPasswordOtpArgs>({
      query: ({ username, isEmail }) =>
        `${apiResources.generateForgetPasswordOtp}?username=${username}&isEmail=${isEmail}`,
    }),
    validateForgetPasswordOtp: builder.mutation<ForgetPasswordToken, ValidateForgetPasswordOtpArgs>({
      query: ({ username, otp, isEmail }) => ({
        url: `${apiResources.validateForgetPasswordOtp}?username=${username}&otp=${otp}&isEmail=${isEmail}`,
        method: 'POST',
      }),
      transformResponse: transformResponse,
    }),
  }),
});

export const {
  useGeneratePartnerOtpMutation,
  useLazyValidatePartnerOtpQuery,
  useGenerateDriverOtpMutation,
  useLazyValidateDriverOtpQuery,
  useLazyGenerateForgetPasswordOtpQuery,
  useValidateForgetPasswordOtpMutation,
} = otpsApi;
