import axios, { AxiosResponse } from 'axios';
import { Authenticator } from '../auth';
import LocalCache from './LocalCache';

const axiosInstance = axios.create();
interface ApiResponse<T = any> {
  status: boolean;
  message?: string;
  error?: string;
  data: T;
}

enum ApiMethod {
  Get,
  Post,
  Put,
  Patch,
  Delete,
}

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const authenticator = new Authenticator();
      const token = await authenticator.refreshToken();
      originalRequest.headers.Authorization = `Bearer ${token}`;
      return axiosInstance(originalRequest);
    }
    return Promise.reject(error);
  },
);

export class Api {
  static isRefreshingToken = false;

  private async executeMethod<DataType>(
    method: ApiMethod,
    url: string,
    params?: any,
    data?: any,
    contentType = 'application/json',
  ) {
    try {
      let response: AxiosResponse<ApiResponse<DataType>>;
      const config = {
        headers: {
          Authorization: 'Bearer ' + LocalCache.fetchToken(),
          'Content-Type': contentType,
        },
        params: params,
      };

      switch (method) {
        case ApiMethod.Post:
          response = await axiosInstance.post<ApiResponse<DataType>>(url, data, config);
          break;
        case ApiMethod.Put:
          response = await axiosInstance.put<ApiResponse<DataType>>(url, data, config);
          break;
        case ApiMethod.Patch:
          response = await axiosInstance.patch<ApiResponse<DataType>>(url, data, config);
          break;
        case ApiMethod.Delete:
          response = await axiosInstance.delete<ApiResponse<DataType>>(url, config);
          break;
        default:
          response = await axiosInstance.get<ApiResponse<DataType>>(url, config);
          break;
      }

      return response.data.data;
    } catch (error: any) {
      if (error.response?.data) {
        const { message } = error.response.data;
        if (Array.isArray(message)) {
          throw message.join('|');
        }

        throw message;
      }

      throw 'Unable to process request.';
    }
  }

  async createResource<DataType>(url: string, data: any = {}): Promise<DataType> {
    return await this.executeMethod(ApiMethod.Post, url, undefined, data);
  }

  async getResourceFromPostMethod<DataType>(url: string, data: any = {}): Promise<DataType> {
    return await this.executeMethod(ApiMethod.Post, url, undefined, data);
  }

  async updateResource<DataType>(url: string, data: any): Promise<DataType> {
    return await this.executeMethod(ApiMethod.Put, url, undefined, data);
  }

  async updatePartialResource<DataType>(url: string, data: any): Promise<DataType> {
    return await this.executeMethod(ApiMethod.Patch, url, undefined, data);
  }

  async deleteResource<DataType>(url: string): Promise<DataType> {
    return await this.executeMethod(ApiMethod.Delete, url);
  }

  async getResource<DataType>(url: string, params?: Record<string, unknown>): Promise<DataType> {
    return await this.executeMethod(ApiMethod.Get, url, params);
  }

  async postFormData(url: string, data: { key: string; value: any }[]) {
    const form = new FormData();
    data.forEach((pair) => {
      form.append(pair.key, pair.value);
    });
    return await this.executeMethod(ApiMethod.Post, url, undefined, form, 'multipart/form-data');
  }
  async postMultiFilesFormData(url: string, data: any) {
    return await this.executeMethod(ApiMethod.Post, url, undefined, data, 'multipart/form-data');
  }
  async putFormData(url: string, data: { key: string; value: any }[]) {
    const form = new FormData();
    data.forEach((pair) => {
      form.append(pair.key, pair.value);
    });
    return await this.executeMethod(ApiMethod.Put, url, undefined, form, 'multipart/form-data');
  }
  async getFile(url: string): Promise<Blob> {
    const response = await axiosInstance.get(url, {
      responseType: 'blob',
      headers: {
        Authorization: 'Bearer ' + LocalCache.fetchToken(),
      },
    });

    return new Blob([response.data]);
  }
}
