import { PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { PushNotification, fetchNotifications, markAllNotificationsAsRead } from 'src/common/Notification';
import { TActionMeta } from '../../store';
import { NotificationsState } from '../notificationsSlice';

export const fetchNotificationsAction = createAsyncThunk('notifications/fetchNotificationsAction', async () => {
  return fetchNotifications();
});

export const handleFetchNotifications = (
  state: NotificationsState,
  action: PayloadAction<PushNotification[], string, TActionMeta, never>,
) => {
  state.status = 'idle';
  state.notifications = action.payload;
};
export const handleFetchNotificationsFail = (
  state: NotificationsState,
  // action: PayloadAction<unknown, string, TActionMeta, { code?: string; message?: string; name?: string }>
) => {
  state.status = 'failed';
};

export const markAllReadAction = createAsyncThunk('notifications/markAllReadAction', async (_, { dispatch }) => {
  await markAllNotificationsAsRead();
  return dispatch(fetchNotificationsAction());
});
