import axios from 'axios';
import { loadPreferencesIntoLocalStorage } from 'src/configuration/Configuration';
import { apiResources } from '../common/Constants';
import LocalCache from '../common/LocalCache';
import { environment } from '../environments/environment';
import { fetchUserByUsername, saveUserInCache } from '../management/User';

export type LoginType = 'shipper' | 'internal';

interface LoginResponse {
  status: boolean;
  data: {
    auth: {
      type: string;
      token: string;
      refreshToken: string;
    };
  };
}

class AuthApi {
  getLoginUrl(loginType: LoginType) {
    if (loginType == 'shipper') return apiResources.loginShipper;
    return apiResources.loginInternal;
  }
  getClientSecretKey = (loginType: LoginType) => {
    let clientSecretKey = 'ACCESS_DENIED';
    if (loginType == 'shipper') clientSecretKey = environment.VITE_CLIENT_SECRET_SHIPPER;
    else clientSecretKey = environment.VITE_CLIENT_SECRET_CSR;
    return clientSecretKey;
  };
  async login(
    loginType: LoginType,
    username: string | undefined,
    password: string | undefined,
    isEmail: boolean,
  ): Promise<LoginResponse> {
    const loginUrl = this.getLoginUrl(loginType);

    const response = await axios.post<LoginResponse>(loginUrl, {
      phoneNumber: username,
      password: password,
      clientSecret: this.getClientSecretKey(loginType),
      isEmail,
    });

    return response.data;
  }

  async refreshToken(): Promise<LoginResponse> {
    const userType: LoginType = LocalCache.fetchUserType()! as LoginType;
    const clientSecretKey = this.getClientSecretKey(userType);
    const response = await axios.post<LoginResponse>(apiResources.refreshToken, {
      refreshToken: LocalCache.fetchRefreshToken(),
      clientSecret: clientSecretKey,
    });
    return response.data;
  }
}

export class Authenticator {
  async authenticate(
    loginType: LoginType,
    username: string | undefined,
    password: string | undefined,
    isEmail: boolean,
  ): Promise<boolean> {
    if (!username || !password || !loginType) {
      throw 'Username and/or password is required';
    }

    try {
      const authApi = new AuthApi();
      const loginResponse = await authApi.login(loginType, username, password, isEmail);
      const { token, refreshToken } = loginResponse.data.auth;
      LocalCache.saveToken(token);
      LocalCache.saveRefreshToken(refreshToken);
      LocalCache.saveUserType(loginType);

      const user = await fetchUserByUsername(username, isEmail);
      saveUserInCache(user);
      await loadPreferencesIntoLocalStorage();
      return true;
    } catch (error: any) {
      console.error(error);
      LocalCache.clearCachedData();
      throw 'Unable to login, please provide a valid username and password';
    }
  }

  logout() {
    LocalCache.clearCachedData();
  }

  async refreshToken() {
    try {
      const authApi = new AuthApi();
      const loginResponse = await authApi.refreshToken();
      const { token, refreshToken } = loginResponse.data.auth;
      LocalCache.saveToken(token);
      LocalCache.saveRefreshToken(refreshToken);
      return token;
    } catch (error: any) {
      console.log('REFRESH TOKEN ERROR', error);
      LocalCache.clearCachedData();
      window.location.href = `/auth/login?redirect=${window.location.pathname}`;
      throw 'Session Expired, please login again';
    }
  }
}
