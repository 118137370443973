import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from 'src/app-redux/rtkq.interceptor';
import { transformResponse } from 'src/app-redux/rtkq.util';
import { apiResources } from 'src/common/Constants';
import {
  DemandAdda,
  DemandAddaChangePasswordArgs,
  DemandAddaCreateArgs,
  DemandAddaFetchArgs,
  demandAddaKeyValuePairs,
  DemandAddaUpdateArgs,
  DemandAddaUpdateStatusArgs,
} from 'src/management/DemandAdda/demand-adda';
import { BlockReasons } from 'src/management/User';

const demandAddasTag = 'demand-addas';

export const demandAddasApi = createApi({
  reducerPath: 'demandAddasApi',
  tagTypes: [demandAddasTag],
  baseQuery: baseQueryWithAuth,
  endpoints: (builder) => ({
    fetchDemandAddas: builder.query<DemandAdda[], DemandAddaFetchArgs>({
      providesTags: [demandAddasTag],
      query: (args) => ({
        url: apiResources.demandAdda,
        method: 'GET',
        params: {
          page: args.page,
          pageSize: args.pageSize,
          [args.search.by]: args.search.keyword,
        },
      }),
      transformResponse: transformResponse,
    }),
    createDemandAdda: builder.mutation<void, DemandAddaCreateArgs>({
      query: (args) => {
        const kv = demandAddaKeyValuePairs(args);
        const form = new FormData();
        kv.forEach(({ key, value }) => form.append(key, value as any));
        return {
          url: apiResources.demandAdda,
          method: 'POST',
          body: form,
        };
      },
      invalidatesTags: (_result, error) => (error ? [] : [demandAddasTag]),
    }),
    updateDemandAdda: builder.mutation<void, DemandAddaUpdateArgs>({
      query: (args) => {
        const kv = demandAddaKeyValuePairs(args);
        const form = new FormData();
        kv.forEach(({ key, value }) => form.append(key, value as any));
        return {
          url: `${apiResources.demandAdda}/${args.projectId}`,
          method: 'PUT',
          body: form,
        };
      },
      invalidatesTags: (_result, error) => (error ? [] : [demandAddasTag]),
    }),
    deleteDemandAdda: builder.mutation<string, number>({
      query: (id) => ({
        url: `${apiResources.demandAdda}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, error) => (error ? [] : [demandAddasTag]),
      transformResponse: transformResponse,
    }),
    getDemandAddaBlockReasons: builder.query<BlockReasons[], void>({
      query: () => ({
        url: `${apiResources.demandAdda}/blockReasons?reasonType=1&userType=1`,
        method: 'GET',
      }),
      transformResponse: (response: { data: BlockReasons[] }) => {
        const data = response.data;
        data.push({ id: null, reason: 'Other' });
        return data;
      },
    }),
    updateDemandAddaBlockStatus: builder.mutation<void, DemandAddaUpdateStatusArgs>({
      query: (args) => {
        return {
          url: `${apiResources.demandAdda}/${args.phoneNumber}/update-status`,
          method: 'POST',
          body: args,
        };
      },
      invalidatesTags: (_result, error) => (error ? [] : [demandAddasTag]),
    }),
    changeDemandAddaPassword: builder.mutation<void, DemandAddaChangePasswordArgs>({
      query: (args) => ({
        url: `${apiResources.demandAdda}/${args.defaultUserId}/changePassword`,
        method: 'POST',
        body: {
          password: args.password,
          confirmPassword: args.confirmPassword,
        },
      }),
      invalidatesTags: (_result, error) => (error ? [] : [demandAddasTag]),
    }),
  }),
});

export const {
  useFetchDemandAddasQuery,
  useCreateDemandAddaMutation,
  useUpdateDemandAddaMutation,
  useDeleteDemandAddaMutation,
  useGetDemandAddaBlockReasonsQuery,
  useUpdateDemandAddaBlockStatusMutation,
  useChangeDemandAddaPasswordMutation,
} = demandAddasApi;
