enum ENUM_DOCUMENT_TYPE {
  BUILTY = 1,
  PROOF_OF_DELIVERY = 2,
  CONTAINER_RELEASE_ORDER = 3,
  CNIC = 4,
  DRIVING_LICENSE = 5,
  VEHICLE_REGISTRATION = 6,
  BILL_OF_LADING = 7,
  DELIVERY_ORDER = 8,
  EQUIPMENT_INTERCHANGE_RECEIPT = 9,
  WEIGHBRIDGE_SLIP = 10,
  GATE_PASS = 11,
  TOKEN = 12,
  ADVANCE_PAYMENT_DETAILS = 13,
  REMAINDER_PAYMENT_DETAILS = 14,
  OTHERS = 15,
  TYRE_INVOICE = 16,
  COMPANY_CARD = 17,
  DIB_RESULTANT_FILE = 18,
  BANK_DEPOSIT_SLIP = 19,
  FUEL_STATION_CASH_RECEIPT = 20,
  DO = 21,
  SEALS = 22,
  ALLOWANCE_LETTER = 23,
  CONTAINER_HANDLING = 24,
  CLEARANCE_CHARGES = 25,
  COMMISSION = 26,
  DEMURRAGE = 27,
  CONTAINER_DAMAGE_CHARGES = 28,
  WEIGHTMENT_CHARGES = 29,
  CRANE_LANDING_CHARGES = 30,
  LOLO_CHARGES = 31,
  OVER_WEIGHT_CHARGES = 32,
  DETENTION = 33,
  LABOR = 34,
  CONTAINER_OFFLOADING = 35,
  EXTRA_STOP_ADDITION_CHARGES = 36,
  OTHER_EXPENSES = 37,
  CANCELLATION_CHARGES_AT_TIN = 38,
  CANCELLATION_CHARGES_AT_SHIPPER = 39,
  INVOICE = 40,
  CANCELLATION = 41,
  FUEL_ADJUSTMENT = 42,
  LOGO = 43,
  PROFILE_IMAGE = 44,
  CHALLAN = 45,
  MUNSHIANA = 46,
  PARTNER_VERIFICATION_DOCUMENT = 47,
  PACKING_LIST = 48,
  COMMERCIAL_INVOICE = 49,
  N9_N4 = 50,
  WAYBILL = 51,
  C1_GATE_PASS = 52,
  MANIFEST = 53,
  IMPORT_ETHIOPIA_PERMIT = 54,
  TWO_X_T1 = 55,
  PORT_FACTURACTION = 56,
  CUSTOM_WAYBILL = 57,
  GATE_PASS_N9 = 58,
  T1_N4 = 59,
  ETHIOPIAN_CUSTOM_DECLARATION = 60,
}

enum ENUM_DEFAULT_BOOKING_DOCUMENT_TYPE {
  BUILTY = ENUM_DOCUMENT_TYPE.BUILTY,
  PROOF_OF_DELIVERY = ENUM_DOCUMENT_TYPE.PROOF_OF_DELIVERY,
  WEIGHBRIDGE_SLIP = ENUM_DOCUMENT_TYPE.WEIGHBRIDGE_SLIP,
}

enum ENUM_PARTNER_DOCUMENT_TYPE {
  CNIC = ENUM_DOCUMENT_TYPE.CNIC,
  DRIVING_LICENSE = ENUM_DOCUMENT_TYPE.DRIVING_LICENSE,
  OTHERS = ENUM_DOCUMENT_TYPE.OTHERS,
  COMPANY_CARD = ENUM_DOCUMENT_TYPE.COMPANY_CARD,
}

export default class DocumentTypeHandler {
  static ENUM_DOCUMENT_TYPE = ENUM_DOCUMENT_TYPE;

  static ENUM_DEFAULT_BOOKING_DOCUMENT_TYPE = ENUM_DEFAULT_BOOKING_DOCUMENT_TYPE;

  static ENUM_PARTNER_DOCUMENT_TYPE = ENUM_PARTNER_DOCUMENT_TYPE;

  static LABELS_DOCUMENT_TYPE = {
    1: 'BILL-T',
    2: 'Proof of delivery',
    3: 'Container release order',
    4: 'CNIC',
    5: 'Driving License',
    6: 'Vehicle Registration',
    7: 'Bill of lading',
    8: 'Delivery order',
    9: 'Equipment interchange receipt',
    10: 'Weighbridge slip',
    11: 'Gate pass',
    12: 'Token',
    13: 'Advance payment details',
    14: 'Remainder payment details',
    15: 'Others',
    16: 'Tyre Invoice',
    17: 'Company Card',
    18: 'DIB Resultant File',
    19: 'Bank Deposit Slip ',
    20: 'Fuel Station Cash Receipt ',
    21: 'DO',
    22: 'Seals',
    23: 'Allowance Letter',
    24: 'Container Handling',
    25: 'Clearance Charges',
    26: 'Commission',
    27: 'Demurrage',
    28: 'Container Damage Charges',
    29: 'Weightment Charges',
    30: 'Crane Landing Charges',
    31: 'Lolo Charges',
    32: 'Over Weight Charges',
    33: 'Detention',
    34: 'Labor',
    35: 'Container Offloading',
    36: 'Extra Stop Addition Charges',
    37: 'Other Expenses',
    38: 'Cancellation Charges At TIN',
    39: 'Cancellation Charges At Shipper',
    40: 'Invoice',
    41: 'Cancellation',
    42: 'Fuel Adjustment',
    43: 'Logo',
    44: 'Profile Image',
    45: 'Challan',
    46: 'Munshiana',
    47: 'Partner Verification Document',
    48: 'Packing List',
    49: 'Commercial Invoice',
    50: 'N9 + N4',
    51: 'Waybill',
    52: 'C1 - Gate Pass',
    53: 'Manifest',
    54: 'Import Ethiopia Permit',
    55: '2xT1',
    56: 'Port Facturaction',
    57: 'Custom Waybill',
    58: 'Gate Pass (N9)',
    59: 'T1 (N4)',
    60: 'Ethiopian Custom Declaration (ECD)',
  };

  static LABEL_BOOKING_DOCUMENT_TYPES = {
    ...Object.fromEntries(
      Object.entries(DocumentTypeHandler.LABELS_DOCUMENT_TYPE).filter(
        ([key]) => Number(key) <= 40 || Number(key) >= 48,
      ),
    ),
  };

  static DEFAULT_BOOKING_DOCUMENT_TYPES = [
    DocumentTypeHandler.ENUM_DEFAULT_BOOKING_DOCUMENT_TYPE.BUILTY,
    DocumentTypeHandler.ENUM_DEFAULT_BOOKING_DOCUMENT_TYPE.PROOF_OF_DELIVERY,
    DocumentTypeHandler.ENUM_DEFAULT_BOOKING_DOCUMENT_TYPE.WEIGHBRIDGE_SLIP,
  ];

  static OPTION_BOOKING_DOCUMENT_TYPES = Object.entries(DocumentTypeHandler.LABEL_BOOKING_DOCUMENT_TYPES)
    .map(([key, value]) => ({
      id: Number(key),
      name: value,
      default: DocumentTypeHandler.DEFAULT_BOOKING_DOCUMENT_TYPES.includes(Number(key)),
    }))
    .sort((a, b) => (a.default ? (b.default ? 0 : -1) : 1));

  static OTHERS_DOC_TYPE_ID = 15;

  static getLabel(id: number): string {
    return DocumentTypeHandler.LABELS_DOCUMENT_TYPE[id as keyof typeof DocumentTypeHandler.LABELS_DOCUMENT_TYPE];
  }
}
