import { configApi } from 'src/app-redux/settings/services/configAPI';
import { customThemeAction } from 'src/app-redux/settings/settingsSlice';
import { store } from 'src/app-redux/store';
import DocumentTypeHandler from 'src/booking/DocumentTypeHandler';
import { SHIPPER_RATE_TERMINOLOGY_OPTIONS, VEHICLE_TYPE_TERMINOLOGY_OPTIONS } from 'src/common/Constants';
import { getLoggedInUser, saveUserInCache, TPreferences } from 'src/management/User';
import { customThemeOptions } from 'src/theme/customTheme';

/**
 * Configuration
 * @deprecated use `TConfig` exported below
 */
export interface Configuration {
  id?: number;
  value: string;
  userFullName: string;
  updateDate: Date;
}

export enum EConfigName {
  THEME = 'THEME',
  SHIPMENT_TYPES = 'SHIPMENT_TYPES',
  PAYMENT_TYPES = 'PAYMENT_TYPES',
  PREFERENCE = 'PREFERENCE',
  EXPANDED_TRIP_STATUSES = 'EXPANDED_TRIP_STATUSES',
  TRIP_AND_DOCUMENT_VERIFICATIONS = 'TRIP_AND_DOCUMENT_VERIFICATIONS',
  VEHICLE_TYPES = 'VEHICLE_TYPES',
  DOCUMENT_TYPES = 'DOCUMENT_TYPES',
  SHIPPER_RATE = 'SHIPPER_RATE',
  GOOGLE_MAP_API = 'GOOGLE_MAP_API',
  POD_REQUIRED = 'POD_REQUIRED',
  VEHICLE_TYPE_TERMINOLOGY = 'VEHICLE_TYPE_TERMINOLOGY',
}

export type TAddConfig = {
  name: EConfigName;
  values: string[];
  description: string;
};

export type TConfig = TAddConfig & {
  id: number;
  clientId: number;
  creationDate: Date;
  updateDate: Date;
  clientFullName: string;
};

export async function loadPreferencesIntoLocalStorage() {
  const user = getLoggedInUser();
  if (!user.id) return;
  const configPromise = store.dispatch(configApi.endpoints.getAllConfigs.initiate({ userId: user.id }));
  let themeConfig: TConfig | undefined;
  let paymentTypes: TConfig | undefined;
  let shipmentTypes: TConfig | undefined;
  let preferenceConfig: TConfig | undefined;
  let expandedTripStatuses: TConfig | undefined;
  let tripVerifications: TConfig | undefined;
  let vehicleTypes: TConfig | undefined;
  let documentTypes: TConfig | undefined;
  let shipperRate: TConfig | undefined;
  let googleMaps: TConfig | undefined;
  let podRequired: TConfig | undefined;
  let vehicleTypeTerminology: TConfig | undefined;
  try {
    const { data: configs } = await configPromise;
    themeConfig = configs?.find((c) => c.name === EConfigName.THEME);
    paymentTypes = configs?.find((c) => c.name === EConfigName.PAYMENT_TYPES);
    shipmentTypes = configs?.find((c) => c.name === EConfigName.SHIPMENT_TYPES);
    preferenceConfig = configs?.find((c) => c.name === EConfigName.PREFERENCE);
    expandedTripStatuses = configs?.find((c) => c.name === EConfigName.EXPANDED_TRIP_STATUSES);
    tripVerifications = configs?.find((c) => c.name === EConfigName.TRIP_AND_DOCUMENT_VERIFICATIONS);
    vehicleTypes = configs?.find((c) => c.name === EConfigName.VEHICLE_TYPES);
    documentTypes = configs?.find((c) => c.name === EConfigName.DOCUMENT_TYPES);
    shipperRate = configs?.find((c) => c.name === EConfigName.SHIPPER_RATE);
    googleMaps = configs?.find((c) => c.name === EConfigName.GOOGLE_MAP_API);
    podRequired = configs?.find((c) => c.name === EConfigName.POD_REQUIRED);
    vehicleTypeTerminology = configs?.find((c) => c.name === EConfigName.VEHICLE_TYPE_TERMINOLOGY);
    if (!themeConfig?.values.length || !paymentTypes?.values.length || !shipmentTypes?.values.length) throw '';
  } catch (error) {
    console.error(error);
    console.error('Unable to fetch configurations!');
  } finally {
    configPromise.unsubscribe();
  }
  const preferences: TPreferences = {
    theme: {
      logoUrl: themeConfig?.values[0] || '/logo.png',
      primary: themeConfig?.values[1] || (customThemeOptions.palette?.primary as any).main,
      secondary: themeConfig?.values[2] || (customThemeOptions.palette?.secondary as any).main,
    },
    terms: {
      paymentTypes: paymentTypes?.values || ['Paid', 'To Pay'],
      shipmentTypes: shipmentTypes?.values || ['Spot', 'Contractual'],
      shipperRate: shipperRate?.values[0] || SHIPPER_RATE_TERMINOLOGY_OPTIONS[0],
      vehicleType: vehicleTypeTerminology?.values[0] || VEHICLE_TYPE_TERMINOLOGY_OPTIONS[0],
    },
    clientName: themeConfig?.values[3] || 'Truck It In',
    currency: preferenceConfig?.values[0] || 'Rs',
    distanceUnit: 'km',
    language: 'en',
    weightUnit: preferenceConfig?.values[1] || 'Tonne',
    expandedTripStatuses: expandedTripStatuses?.values[0] === 'enabled',
    tripVerifications: tripVerifications?.values[0] === 'enabled',
    vehicleTypes: vehicleTypes?.values.map((v) => Number(v)) || [],
    documentTypes: documentTypes?.values.map((v) => Number(v)) || DocumentTypeHandler.DEFAULT_BOOKING_DOCUMENT_TYPES,
    googleMaps: {
      apiKey: googleMaps?.values[0] || '',
      mapId: googleMaps?.values[1] || '',
    },
    podRequired: podRequired?.values[0] === 'enabled',
  };
  saveUserInCache({
    ...user,
    preferences,
  });
  store.dispatch(customThemeAction({ primary: preferences.theme.primary, secondary: preferences.theme.secondary }));
}
