export interface DemandAddaSearchInterface {
  by: string;
  keyword: string;
}

export interface DemandAddaCreateArgs {
  companyGroupName: string;
  countryId: number;
  provinceId: number;
  cityId: number;
  address: string;
  logo?: File;
  projectName: string;
  email: string;
  phoneNumber: string;
  strn: string;
  marginTypeId: number;
  marginValue: number;
  operationalCities: number[];
  identificationType: number;
  identificationValue: string;
}

export interface DemandAddaUpdateArgs extends DemandAddaCreateArgs {
  companyGroupId: number;
  projectId: number;
}

export interface DemandAddaChangePasswordArgs {
  defaultUserId: number;
  password: string;
  confirmPassword: string;
}

export interface DemandAdda {
  address: string;
  cityId: number;
  provinceId: number;
  countryId: number;
  city: string;
  companyGroupId: number;
  country: string;
  defaultUserId: number;
  email: string;
  groupName: string;
  identificationTypeId: number;
  identificationValue: string;
  operationalCities: number[];
  operationalCitiesNames: string[];
  logoUrl: string;
  logoId: number;
  marginTypeId: number;
  marginValue: number;
  phoneNumber: string;
  projectId: number;
  projectName: string;
  province: string;
  strn: string;
  totalPages?: number;
  isBlocked: boolean;
  id: number;
}

export interface DemandAddaFetchArgs {
  page: number;
  pageSize: number;
  search: DemandAddaSearchInterface;
}

export interface DemandAddaUpdateStatusArgs {
  phoneNumber: string;
  userType: 1;
  block: boolean;
  reasonId: number | null;
  reason: string | null;
}

type TKeyValue = {
  key: string;
  value: string | number | File | number[];
};

export function demandAddaKeyValuePairs(args: DemandAddaCreateArgs & Partial<DemandAddaUpdateArgs>): TKeyValue[] {
  const out: TKeyValue[] = [
    {
      key: 'companyGroupName',
      value: args.companyGroupName,
    },
    {
      key: 'countryId',
      value: args.countryId,
    },
    {
      key: 'provinceId',
      value: args.provinceId,
    },
    {
      key: 'cityId',
      value: args.cityId,
    },
    {
      key: 'address',
      value: args.address,
    },
    {
      key: 'projectName',
      value: args.projectName,
    },
    {
      key: 'email',
      value: args.email,
    },
    {
      key: 'phoneNumber',
      value: args.phoneNumber,
    },
    {
      key: 'strn',
      value: args.strn,
    },
    {
      key: 'marginTypeId',
      value: args.marginTypeId,
    },
    {
      key: 'marginValue',
      value: args.marginValue,
    },
    {
      key: 'identificationType',
      value: args.identificationType,
    },
    {
      key: 'identificationValue',
      value: args.identificationValue,
    },
  ];

  if (args.logo !== undefined) {
    out.push({ key: 'logo', value: args.logo });
  }

  if (args.companyGroupId) {
    out.push({ key: 'companyGroupId', value: args.companyGroupId });
  }

  if (args.operationalCities) {
    out.push({
      key: 'operationalCities',
      value: args.operationalCities,
    });
  }

  return out;
}
