import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from 'src/app-redux/rtkq.interceptor';
import { partnersTag } from 'src/app-redux/rtkq.tags';
import { transformResponse } from 'src/app-redux/rtkq.util';
import { apiResources } from 'src/common/Constants';
import { User } from 'src/management/User';

export const partnersApi = createApi({
  reducerPath: 'partnersApi',
  tagTypes: [partnersTag],
  baseQuery: baseQueryWithAuth,
  endpoints: (builder) => ({
    getPartners: builder.query<User[], { page: number; searchParam: { [id: string]: string } }>({
      providesTags: [partnersTag],
      query: (args) => {
        return {
          url: apiResources.partners,
          params: { page: args.page, ...args.searchParam },
          method: 'GET',
        };
      },
      transformResponse: transformResponse,
    }),
  }),
});

export const { useGetPartnersQuery, useLazyGetPartnersQuery } = partnersApi;
