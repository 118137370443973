// This file can be replaced during build by using the `fileReplacements` array.
// When building for production, this file is replaced with `environment.prod.ts`.

export const environment = {
  production: import.meta.env.PROD,
  // NODE_ENV: 'development',
  MODE: import.meta.env.MODE,

  VITE_SENTRY_DNS: import.meta.env.VITE_SENTRY_DNS,

  VITE_API_ENDPOINT: import.meta.env.VITE_API_ENDPOINT,
  // VITE_APP_API_ENDPOINT: 'https://da-apistaging.truckitin.com',
  // VITE_APP_API_ENDPOINT: 'http://localhost:8080',
  VITE_CLIENT_SECRET_CSR: import.meta.env.VITE_CLIENT_SECRET_CSR,
  VITE_CLIENT_SECRET_SHIPPER: import.meta.env.VITE_CLIENT_SECRET_SHIPPER,
  VITE_GOOGLE_MAP_API_KEY: import.meta.env.VITE_GOOGLE_MAP_API_KEY,
  VITE_GOOGLE_MAP_ID: import.meta.env.VITE_GOOGLE_MAP_ID,
  VITE_GOOGLE_ANALYTICS_KEY: import.meta.env.VITE_GOOGLE_ANALYTICS_KEY,

  VITE_BASE_URL: import.meta.env.VITE_BASE_URL,
  VITE_IPINFO_TOKEN: import.meta.env.VITE_IPINFO_TOKEN,
};
