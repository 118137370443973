import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from 'src/app-redux/rtkq.interceptor';
import { transformResponse } from 'src/app-redux/rtkq.util';
import { apiResources } from 'src/common/Constants';
import { Fleet, ICreateFleetArgs } from 'src/management/Fleet';

const fleetsTag = 'fleets';

export const fleetsApi = createApi({
  reducerPath: 'fleetsApi',
  tagTypes: [fleetsTag],
  baseQuery: baseQueryWithAuth,
  endpoints: (builder) => ({
    fetchFleets: builder.query<Fleet[], void>({
      providesTags: [fleetsTag],
      query: () => apiResources.fleet,
      transformResponse: transformResponse,
    }),
    createFleet: builder.mutation<Fleet, ICreateFleetArgs>({
      query: (fleet) => {
        return {
          url: apiResources.fleet,
          method: 'POST',
          body: fleet,
        };
      },
      invalidatesTags: (_result, error) => (error ? [] : [fleetsTag]),
    }),
  }),
});

export const { useCreateFleetMutation, useFetchFleetsQuery } = fleetsApi;
