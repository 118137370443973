import type { PaletteMode } from '@mui/material';
import { purple } from '@mui/material/colors';
import { type ThemeOptions } from '@mui/material/styles';

export const customThemeOptions: ThemeOptions = {
  typography: {
    fontFamily: 'Inter',
    button: { textTransform: 'none' },
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#4088cc',
    },
    secondary: {
      main: '#FFB500',
    },
  },
  components: {
    MuiAvatar: {
      styleOverrides: {
        root: { border: '1px solid #ddd' },
        colorDefault: {
          backgroundColor: 'transparent',
          color: '#000',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableElevation: true,
      },
      styleOverrides: { root: { borderRadius: 100 } },
    },
    MuiRadio: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        className: 'inputRounded',
        slotProps: {
          inputLabel: {
            // shrink: true,
          },
        },
      },
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
    MuiTableRow: {
      defaultProps: {
        hover: true,
        className: 'cur-pointer',
      },
      styleOverrides: {
        root: {
          '&.Mui-selected, &.Mui-selected:hover': {
            backgroundColor: purple[100],
            borderRadius: '16px',
          },
        },
      },
    },
    MuiCard: {
      defaultProps: {
        variant: 'outlined',
      },
      styleOverrides: {
        root: {
          borderRadius: 16,
          background: '#fafafa',
        },
      },
    },
  },
};

export function getTheme(primary: string, secondary: string): Record<PaletteMode, ThemeOptions> {
  return {
    light: {
      ...customThemeOptions,
      palette: {
        ...customThemeOptions.palette,
        primary: {
          main: primary,
        },
        secondary: {
          main: secondary,
        },
        mode: 'light',
      },
      components: {
        ...customThemeOptions.components,
        MuiAvatar: {
          styleOverrides: {
            root: { border: '1px solid #ddd' },
          },
        },
        MuiCard: {
          defaultProps: {
            variant: 'outlined',
          },
          styleOverrides: {
            root: {
              borderRadius: 16,
              background: '#fafafa',
            },
          },
        },
      },
    },
    dark: {
      ...customThemeOptions,
      palette: {
        ...customThemeOptions.palette,
        primary: {
          main: primary,
        },
        secondary: {
          main: secondary,
        },
        mode: 'dark',
      },
      components: {
        ...customThemeOptions.components,
        MuiAvatar: {
          styleOverrides: {
            // root: { border: '1px solid #ddd' },
          },
        },
        MuiCard: {
          defaultProps: {
            variant: 'outlined',
          },
          styleOverrides: {
            root: {
              borderRadius: 16,
              // background: '#fafafa',
            },
          },
        },
      },
    },
  };
}
