import { createSlice } from '@reduxjs/toolkit';
import { PushNotification } from '../../common/Notification';
import { type RootState } from '../store';
import {
  fetchNotificationsAction,
  handleFetchNotifications,
  handleFetchNotificationsFail,
  markAllReadAction,
} from './actions/notificationActions';

export interface NotificationsState {
  notifications: Array<PushNotification>;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: NotificationsState = {
  notifications: [],
  status: 'idle',
};

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotificationsAction.rejected, handleFetchNotificationsFail)
      .addCase(fetchNotificationsAction.fulfilled, handleFetchNotifications)
      .addCase(fetchNotificationsAction.pending, (state) => {
        state.status = 'loading';
      })

      .addCase(markAllReadAction.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(markAllReadAction.fulfilled, (state) => {
        state.status = 'idle';
      })
      .addCase(markAllReadAction.pending, (state) => {
        state.status = 'loading';
      });
  },
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectNotifications = (state: RootState) => state.notifications.notifications;
