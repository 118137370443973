import { lazy, Suspense } from 'react';

const App = lazy(() => import('./App'));

/** Helps in splitting `react`/`react-redux` chunks from rest of the app. */
export default function LazyApp(): React.ReactElement {
  console.log('🔄 LazyApp Rendered');
  return (
    <Suspense>
      <App />
    </Suspense>
  );
}
