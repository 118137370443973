/** Dynamic import for code splitting/lazy loading. */
const googleLibphoneNumberModule = () => import('google-libphonenumber');

let phoneUtil: libphonenumber.PhoneNumberUtil;
const modulePromise = googleLibphoneNumberModule().then(({ default: { PhoneNumberUtil } }) => {
  phoneUtil = PhoneNumberUtil.getInstance();
  console.log('💥💥💥 google-libphonenumber loaded');
});

type TRule = { startsWith: string; greaterThan: number; lessThan: number };
const customList: TRule[] = [
  { startsWith: '+253', greaterThan: 11, lessThan: 13 },
  { startsWith: '+251', greaterThan: 12, lessThan: 14 },
];
function customValidation(phone: string, rule: TRule) {
  if (phone.length > rule.greaterThan && phone.length < rule.lessThan) return true;
  return false;
}

export async function isPhoneNumberValid(phone: string) {
  try {
    const rule = customList.find((custom) => phone.startsWith(custom.startsWith));
    if (rule) return customValidation(phone, rule);

    await modulePromise;
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    console.error(error);
    return false;
  }
}

export async function isPhoneNumberPossible(phone: string) {
  try {
    await modulePromise;
    return phoneUtil.isPossibleNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    console.error(error);
    return false;
  }
}
