import { CacheProvider } from '@emotion/react';
import { useMediaQuery } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useEffect, useMemo } from 'react';
import { getLoggedInUser } from 'src/management/User';
import { useAppDispatch, useAppSelector } from '../app-redux/hooks';
import { customThemeAction, selectCustomTheme } from '../app-redux/settings/settingsSlice';
import { getTheme } from './customTheme';
import { muiCache } from './muiConfig';
import LocalCache from 'src/common/LocalCache';

// import { Theme } from "@mui/material/styles";
// declare module "@mui/styles/defaultTheme" {
//   // eslint-disable-next-line @typescript-eslint/no-empty-interface (remove this line if you don't have the rule enabled)
//   interface DefaultTheme extends Theme {}
// }

interface Props {
  children: React.ReactNode;
}
export default function CustomMuiTheme(props: Props) {
  const user = getLoggedInUser();
  const dispatch = useAppDispatch();
  const customTheme = useAppSelector(selectCustomTheme);

  const goDarkQuery = useMediaQuery('(prefers-color-scheme: dark)');
  const goLightQuery = useMediaQuery('(prefers-color-scheme: light)');

  useEffect(() => {
    const localTheme = LocalCache.getTheme();
    if (localTheme) {
      return;
    }
    if (goDarkQuery) {
      dispatch(customThemeAction({ mode: 'dark' }));
    }
    if (goLightQuery) {
      dispatch(customThemeAction({ mode: 'light' }));
    }
  }, [dispatch, goDarkQuery, goLightQuery]);

  const theme = useMemo(() => {
    return createTheme(
      getTheme(
        user?.preferences?.theme?.primary || customTheme.primary,
        user?.preferences?.theme?.secondary || customTheme.secondary,
      )[customTheme.mode],
    );
  }, [customTheme, user]);

  return (
    <CacheProvider value={muiCache}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {props.children}
      </ThemeProvider>
    </CacheProvider>
  );
}
